import React, {useEffect, useState} from 'react';
import SectionHeader from '../../components/My/SectionHeader';
import DiscountSection from '../../components/My/DiscountSection';
import MembershipGrade from '../../components/My/MembershipGrade';
import VipkokBenefit from '../../components/My/VipkokBenefit';
import UseHistory from '../../components/My/UseHistory';
import MyBadges from '../../components/My/MyBadges';
import MyMenu from '../../components/My/MyMenu';
import MissionReword from '../../components/My/MissionReword';
import CommonDivider from '../../components/Common/CommonDivider';
import membershipsServiceApi from '../../api/gatewayApi/memberships-service';
import {partnerApi} from '../../api/gatewayApi/partnerAndAffiliate-service';
import nftApi from '../../api/gatewayApi/nft';
import {
    BenefitSummery,
    BenefitThisMonth,
    initialBenefitSummary,
    initialMonthUseHistory,
    MissionReward,
    MoneyBenefit,
    MonthUseHistory,
    MyBadge
} from './types';
import PartnerCarousel from '../../components/Partner/PartnerCarousel';
import NavigationBar from '../../components/Common/NavigationBar';
import {brazeEventApi} from '../../api/brazeBridge';
import {LikedPartners} from '../Partner/types';
import {handlePromiseResult} from "../../api/api";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import {useNavigate} from 'react-router-dom';
import otherApi from "../../api/gatewayApi/other";
import TitleHeader from "../../components/Common/TitleHeader";
import {gtmPageLoad} from "../../api/gtm";

const initialBenefitThisMonth: BenefitThisMonth = {
    isVIP: '',
    currentServerDate: '',
    useVipkok: [],
};

const initialMyBadge: MyBadge = {
    sendableCount: '0',
    badgeCount: '0',
    categoryList: [],
};

const initialUseHistory: MonthUseHistory = {
    maxPage: '0',
    currPage: '0',
    monthUsePoint: '0',
    pointUseToCnt: '0',
    pointUseCnt: '0',
    pointUseList: [],
};

const initialLikedPartners: LikedPartners = {
    ptnList: [],
};

const initialMissionReward: MissionReward = {
    rewardPoint: '0',
};

const initialMoneyBenefit: MoneyBenefit = {
    moneyMePoint: '0',
    moneyMeUse: 'N',
};

interface MyProps {
    navigationBarRef: React.Ref<any>;
}

const My: React.FC<MyProps> = ({navigationBarRef}) => {
    const navigate = useNavigate();
    const [benefitSummary, setBenefitSummary] = useState<BenefitSummery>(initialBenefitSummary);
    const [benefitThisMonth, setBenefitThisMonth] = useState<BenefitThisMonth>(initialBenefitThisMonth);
    const [myBadge, setMyBadge] = useState<MyBadge>(initialMyBadge);
    const [useHistory, setUseHistory] = useState<MonthUseHistory>(initialUseHistory);
    const [likedPartners, setLikedPartners] = useState<LikedPartners>(initialLikedPartners);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [memberStatus, setMemberStatus] = useState<string>('')
    const [userStatus, setUserStatus] = useState('');
    const [missionReward, setMissionReward] = useState<MissionReward>(initialMissionReward);
    const [moneyBenefit, setMoneyBenefit] = useState<MoneyBenefit>(initialMoneyBenefit);

    useEffect(() => {
        const fetchData = async () => {
            const moneyMeUse = await getItemByKey('moneyMeUse');
            try {
                const birthday = await getItemByKey('birthday');
                const year = new Date().getFullYear()
                const month = new Date().getMonth() + 1
                const [benefitThisMonth,  useHistory, likedPartners, missionReward] = await Promise.allSettled(
                    [
                        membershipsServiceApi.getVipBenefitThisMonth('v1'),
                        // nftApi.getMyBadge('v1'),
                        membershipsServiceApi.getUseHistory(`${year}${month < 10 ? '0' + month : month}`, 'v1'),
                        partnerApi.getPartnersLike({}, 'v1'),
                        otherApi.getMissionReward('v1'),
                    ]
                );
                // setBenefitSummary(handlePromiseResult(benefitSummary, initialBenefitSummary));
                setBenefitThisMonth(handlePromiseResult(benefitThisMonth, initialBenefitThisMonth));
                // setMyBadge(handlePromiseResult(myBadge, initialMyBadge));
                const useHistoryRes: MonthUseHistory = handlePromiseResult(useHistory, initialMonthUseHistory);
                if (useHistoryRes.pointUseList === null) {
                    useHistoryRes.pointUseList = [];
                }
                setUseHistory(useHistoryRes);
                setLikedPartners(handlePromiseResult(likedPartners, initialLikedPartners));
                setMissionReward(handlePromiseResult(missionReward, initialMissionReward));

                membershipsServiceApi.getVipBenefitSummary({
                    userBirth: birthday,
                    totPoint: useHistoryRes.monthUsePoint || '0',
                    yearMonth: `${year}${month < 10 ? '0' + month : month}`,
                }, 'v1').then(res => {
                    if (res.header.resultCode === '0000' && res.body) {
                        setBenefitSummary(res.body);
                    }
                });

                try {
                    if (moneyMeUse === 'Y') {
                        otherApi.getMoneyBenefit('v1').then((res) => {
                            if (res.header.resultCode === '0000' && res.body) {
                                addItem('moneyMePoint', res.body.moneyMePoint);
                                setMoneyBenefit({moneyMePoint: res.body.moneyMePoint, moneyMeUse: moneyMeUse});
                            }
                        });
                    }
                } catch (error) {
                    console.log('Failed to fetch money benefit:', error);
                }
            } catch (error) {
                addItem('rewardPoint', -1);
                console.log('Failed to fetch my:', error);
            } finally {
                // setIsLoading(false);
                brazeEventApi.my_tab_enter(moneyMeUse === 'Y', Number(moneyBenefit.moneyMePoint));
                await gtmPageLoad({
                    url: '/my',
                    contentGroup: '마이',
                    moduleName: '프로필|사용 내역|VIP콕 혜택|내 배지|내 메뉴|즐겨찾기 제휴사|미션리워드|머니Me 현황',
                    pageTitle: '마이'
                });
            }
        };

        const fetchIndexedDb = async () => {
            const memberId = await getItemByKey('memberId')
            if (!memberId) {
                navigate('/')
            }
            setMemberStatus(await getItemByKey('memberStatus'));
            setUserStatus(await getItemByKey('userStatus'));
        };

        fetchIndexedDb();
        fetchData();
    }, []);

    useEffect(() => {
        const handleBeforeUnload = async () => {
            // const moneyMeUse = await getItemByKey('moneyMeUse');
            // brazeEventApi.my_tab_exit(moneyMeUse === 'Y', Number(moneyBenefit.moneyMePoint));
        };
        return () => {
            handleBeforeUnload();
        }
    }, [navigate]);

    useEffect(() => {
        //braze용 rewardPoint indexdDB추가
        console.log("rewardPoint : " + missionReward.rewardPoint)
        addItem('rewardPoint', missionReward.rewardPoint);
    }, [missionReward])

    return (
        <>
            {/*{useLoading(isLoading)}*/}
            <div style={{paddingBottom: '96px'}}>
                {memberStatus === '5' ? (
                    <TitleHeader title='마이' isSetting={false}/>
                ) : (
                    <>
                        <SectionHeader/>
                        <DiscountSection summary={benefitSummary} date={benefitThisMonth.currentServerDate}/>
                    </>
                )}


                {/*----------------------- 멤버십 등급 --------------------*/}
                <MembershipGrade summary={benefitSummary}/>
                <CommonDivider/>

                {/*----------------------- 내가 받은 혜택 --------------------*/}
                {/* TODO: Vipkok 사용 내역 API 추가 필요 */}
                {memberStatus !== '5' && (
                    <>
                        <UseHistory history={useHistory}/>
                        <CommonDivider/>
                    </>
                )}

                {/*----------------------- VIP콕 -------------------------*/}
                <VipkokBenefit vipkok={benefitThisMonth}/>
                <CommonDivider/>

                {/*-------------------------- 내 배지 -----------------------*/}
                {/*{(memberStatus === '1' || memberStatus === '2') && (*/}
                {/*    <>*/}
                {/*        <MyBadges myBadge={myBadge}/>*/}
                {/*        <CommonDivider/>*/}
                {/*    </>*/}
                {/*)}*/}

                {/*----------------------- 내 메뉴 --------------------*/}
                <MyMenu memberStatus={memberStatus} userStatus={userStatus}/>
                <CommonDivider/>

                {/*----------------------- 좋아하는 제휴사 --------------------*/}
                <PartnerCarousel partnerList={likedPartners.ptnList}/>
                <CommonDivider/>

                {/*----------------------- 미션 리워드 & 머니 --------------------*/}
                {(memberStatus !== '3' && memberStatus !== '4') && (
                    <MissionReword missionReward={missionReward} moneyBenefit={moneyBenefit}/>)}

            </div>
            <NavigationBar selectedItem='my' ref={navigationBarRef}/>
        </>
    );
};

export default My;
