import CouponItem from "../../components/ListItem/CouponItem";

export interface BenefitSummery {
    cardlevel: string; // 등급 코드
    cardLevelName: string; // 등급 명 (VVIP, VIP, 우수, 일반)
    isVIP: string; // VIP 이상 등급 여부 (VIP 이상: Y)
    longTermCode: string; // 장기고객 대상 코드
    longTermName: string; // 장기고객 명
    youthYn: string; // 유쓰 대상자여부
    youthName: string; // 유쓰 명칭
    totalDiscount: string; // 당해년도 누적할인 금액
    thisMonthDiscount: string; // 당월 누적할인 금액
    userMonthAverageDiscount: string; // 당월 사용자 전체 평균 할인금액
    ranking: string; // 당월 할인금액 랭킹 (퍼센트값)
}

export const initialBenefitSummary: BenefitSummery = {
    cardlevel: '',
    cardLevelName: '',
    isVIP: '',
    longTermCode: '',
    longTermName: '',
    youthYn: '',
    youthName: '',
    totalDiscount: '',
    thisMonthDiscount: '0',
    userMonthAverageDiscount: '',
    ranking: '',
};

export interface UseVipkok {
    ptnId: string; // 제휴처 ID
    ptnName: string; // 제휴처 명
    ptnLogoImgUrl: string; // 제휴처 로고 이미지 URL
    // benefitSummary: string; // 혜택 요약
    // useStartDate: string; // 해당 제휴처 혜택 시작날짜(YYYYMMDD)
    // useEndDate: string; // 해당 제휴처 혜택 종료날짜(YYYYMMDD)
    isUsed: string; // 혜택 사용여부 (Y: 혜택 사용)
}

export interface BenefitThisMonth {
    isVIP: string; // VIP 이상 등급 여부 (true: VIP등급 이상)
    currentServerDate: string; // 서버기준 현재 날짜 (YYYYMMDD)
    useVipkok: UseVipkok[]; // 사용한 VIP콕 혜택 정보 (optional)
}

export interface Badge {
    count: string;
    categoryId: string;
    categoryName: string;
}

// User information type with badges
export interface MyBadge {
    sendableCount: string;
    badgeCount: string;
    categoryList: Badge[];
}

export interface MissionReward {
    rewardPoint: string;
}

export interface MoneyBenefit {
    moneyMePoint: string;
    moneyMeUse: string;
}

export interface Grade {
    cardlevel: string; // 등급 코드
    cardLevelName: string;
    longTermCode: string;
    longTermName: string;
    youthYn: string;
    youthName: string;
    levelAplPrd: string;
    levelPayAmt: string;
    subsPrd: string;
    levelUpDate: string;
    nxtYrLevel: string; // 내년도 예상등급코드
    nxtYrLevelName: string; // 내년도 예상등급명
    nxtYrLevelAplPrd: string; // 내년도 등급 신청기간
    nxtYrTotalPayAmt: string; // 내년도 총 납부금액 (예상 또는 충족 조건 금액)
}

export interface Tips {
    levelUpTipList: Tip[];
}

export interface Tip {
    title: string;
    contents: string;
    tipImgUrl: string;
}

export interface Partner {
    ptnId: string;
    ptnLogoImgUrl: string;
    ptnName: string;
    benefitSummary: string;
    startDate: string;
    endDate: string;
}

export interface UsedHistory {
    totalCnt: number;
    pointUseList: UsedHistoryItem[];
}

export interface UsedHistoryItem {
    "bizCatCode": string, // LIFE
    "useSiteNm": string, // "[VIP콕]윌라 윌라"
    "useKokYn": string,
    "usePt": number,
    "useDt": string, // 2024-05-13 00:00:00
    "coopCode": string
}

export interface CouponItem {
    ptnId: string;
    ptnLogoImgUrl: string;
    ptnName: string;
    cpnId: string;
    cpnKey: string;
    isIssuedCoupon: string;
    cpnType: string;
    barcodeType: string;
    issuedDate: string;
    expStartDate: string;
    expEndDate: string;
    summary: string;
    cpnName: string;
}

export const initCouponItem: CouponItem = {
    ptnId: '',
    ptnLogoImgUrl: '',
    ptnName: '',
    cpnId: '',
    cpnKey: '',
    isIssuedCoupon: '',
    cpnType: '',
    barcodeType: '',
    issuedDate: '',
    expStartDate: '',
    expEndDate: '',
    summary: '',
    cpnName: ''
};

export interface IssuedCoupon {
    useYcouponCnt: string;
    useNcouponCnt: string;
    couponList: CouponItem[];
}

export interface CouponSort {
    useYn: string; // - N: 사용 가능한 쿠폰   D:사용/기간만료 쿠폰  U:사용 완료쿠폰  E: 기간 만료 쿠폰
    sortType: string; // N: 신규 발급, S: 마감임박
}

export const initialIssuedCoupon: IssuedCoupon = {
    useYcouponCnt: '0',
    useNcouponCnt: '0',
    couponList: []
};

export const initialCouponSort: CouponSort = {
    useYn: 'N',
    sortType: 'N'
}

export interface CouponInfo {
    ptnId: string;
    ptnLogoImgUrl: string;
    ptnName: string;
    cpnId: string;
    cpnKey: string;
    summary: string;
    cpnType: string;          //
    barcodeType: string;      // 바코드 종류(타입). 예: "111006" 현장 사용형 쿠폰, "111007" 비바코드, 그 외는 바코드형
    useYn: string;
    cpnNo: string;            // 쿠폰번호(바코드, 비바코드 용)
    regDate: string;
    expStartDate: string;
    expEndDate: string;
    homepageUrl: string;
    useGuide: string;
}

export const initCouponInfo: CouponInfo = {
    ptnId: '',
    ptnLogoImgUrl: '',
    ptnName: '',
    cpnId: '',
    cpnKey: '',
    summary: '',
    cpnType: '',
    barcodeType: '',
    useYn: '',
    cpnNo: '',
    regDate: '',
    expStartDate: '',
    expEndDate: '',
    homepageUrl: '',
    useGuide: '',
}

export interface PointUse {
    bizCatCode: string;
    useSiteNm: string;
    useKokYn: string;
    usePt: string;
    useDt: string;
    coopCode: string;
}

export interface MonthUseHistory {
    maxPage: string;
    currPage: string;
    pointUseToCnt: string;
    pointUseCnt: string;
    monthUsePoint: string;
    pointUseList: PointUse[];
}

export const initialMonthUseHistory: MonthUseHistory = {
    maxPage: '',
    currPage: '',
    pointUseToCnt: '',
    pointUseCnt: '',
    monthUsePoint: '',
    pointUseList: [],
};

export interface RecommendPartner {
    ptnId: string;
    ptnLogoImgUrl: string;
    ptnName: string;
    benefitSummary: string;
}

export interface GradeInfo {
    name: string;
    memberStatus: string;
    cardLevel: string;
    isVIP: string;
    longTermName: string;
    youthYn: string;
}

export const initGradeInfo: GradeInfo = {
    name: '',
    memberStatus: '',
    cardLevel: '',
    isVIP: '',
    longTermName: '',
    youthYn: '',
};

export const getGradeName = (cardLevel: string) => {
    switch (cardLevel) {
        case '6':
            return 'VVIP';
        case '7':
            return '우수';
        case '8':
            return 'VIP';
        default:
            return '일반';
    }
}
export const getCouponTypeName = (type: string) => {
    switch (type) {
        case '102001':
            return '일반 쿠폰(고정번호)';
        case '102002':
            return '일반 쿠폰(자동번호)';
        case '102003':
            return '리스트 쿠폰';
        case '102004':
            return '제휴사 연동형 쿠폰(묶음쿠폰용)';
        case '102005':
            return '매칭 쿠폰';
        case '102006':
            return '현장 사용형 쿠폰';
        case '102007':
            return '리스트 쿠폰(묶음쿠폰용)';
        default:
            return '쿠폰';
    }
};