import {Button, Icon, Image, Text} from '@lguuxe/2024_designsystem_uni';
import React, {useEffect, useState} from 'react';
import Snackbar from '../Common/Snackbar';
import {partnerApi} from "../../api/gatewayApi/partnerAndAffiliate-service";
import {ProfileInfo} from "../../pages/Partner/types";
import CommonPopup from "../Common/CommonPopup";
import BS_Barcode from "../Barcode/BS_Barcode";
import defaultThumbnail from '../../images/default/default thumbnail.svg';
import useDebounce from "../../hooks/useDebounce";
import {gtmDeleteComp, gtmFavoriteComp} from "../../api/gtm";
import {formatLikeNum} from "../../utils/currencyUtils";
import { brazeEventApi } from '../../api/brazeBridge';

export interface PartnerProfileProps {
    profileInfo: ProfileInfo;
    // onLikeClick: (profileInfo: ProfileInfo) => void;
}

const PartnerProfile: React.FC<PartnerProfileProps> = ({profileInfo}) => {
    const [isLike, setIsLike] = useState(profileInfo.isLike);
    const [likeNum, setLikeNum] = useState(Number(profileInfo.likeNum));
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showBarcode, setShowBarcode] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    useEffect(() => {
        setIsLike(profileInfo.isLike);
        setLikeNum(Number(profileInfo.likeNum));
    }, [profileInfo.isLike, profileInfo.likeNum]);

    const fetchLike = async () => {
        try {
            const requestParam = {
                regYn: isLike === 'Y' ? 'N' : 'Y',
            }
            return await partnerApi.putPartnersLikeById(profileInfo.ptnId, requestParam, 'v1').then((res) => {
                console.log('fetchLike res:', res);
                if (res.header.resultCode === '0000') {
                    if (isLike === 'N') { // 좋아요 추가시 GTM 호출
                        gtmFavoriteComp(profileInfo.ptnName);
                    } else {
                        gtmDeleteComp('제휴사 즐겨찾기 삭제', profileInfo.ptnName);
                    }
                    return 'success';
                } else {
                    return 'fail';
                }
            });

        } catch (error) {
            return 'fail';
            console.log('Failed to fetch :', error);
        }
    }

    const handleClickLike = useDebounce(async () => {
        const res = await fetchLike();
        if (res === 'success') {
            if (isLike === 'Y') {
                brazeEventApi.my_preferred_affiliate_cancel(profileInfo.ptnName, profileInfo.ptnId, profileInfo.ptnCateName || "");
                setLikeNum(likeNum - 1 < 0 ? 0 : likeNum - 1);
            } else {
                brazeEventApi.favorite_click(profileInfo.ptnName, profileInfo.ptnId, profileInfo.ptnCateName || "");
                setLikeNum(likeNum + 1);
            }
            setIsLike(isLike === 'Y' ? 'N' : 'Y');

            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 1000);
        } else {
            setShowErrorPopup(true);
        }
    })

    const handleClickBarcode = () => {
        setShowBarcode(!showBarcode);
    };

    const renderButtonGroup = (showBarcodeButton: boolean) => (
        <div style={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'center',
            width: '100%',
        }}>
            <Button fill='outline' color="secondary" style={{
                display: 'flex',
                justifyContent: 'center',
                flex: showBarcodeButton ? undefined : '1',
                width: showBarcodeButton ? '75px' : '100%',
            }} onClick={handleClickLike}>
                {isLike === 'Y' ? (
                    <Icon name='favorite_filled' color='#E6007E'></Icon>
                ) : (
                    <Icon name='favorite_line'></Icon>
                )}
                {formatLikeNum(likeNum.toString())}
            </Button>
            {showBarcodeButton && (
                <Button color='secondary' fill='solid'
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '237px',
                            flex: '1',
                        }}
                        onClick={handleClickBarcode}
                        data-gtm-event-name="all_click"
                        data-gtm-event-category="클릭"
                        data-gtm-event-action={`혜택|제휴사|제휴사 상세|${profileInfo.ptnName} - 링크 클릭`}
                        data-gtm-event-label="컨텐츠 : U+멤버십 바코드"
                        data-gtm-click-url=""
                        data-gtm-click-location={`혜택|제휴사|제휴사 상세|${profileInfo.ptnName}`}
                        data-gtm-click-direction="내부"
                        data-gtm-click-text="U+멤버십 바코드"
                        data-gtm-affiliate-id={profileInfo.ptnId}
                        data-gtm-affiliate-name={profileInfo.ptnName}
                >
                    U+멤버십 바코드
                </Button>
            )}
        </div>
    );

    return (
        <>

            <div style={{
                display: 'flex',
                gap: '32px',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '24px 20px',
                borderRadius: '12px',
                backgroundColor: '#fff',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '12px',
                    alignSelf: 'stretch',
                }}>
                    <Image
                        customClass='customClass'
                        ratio='1:1'
                        rounded='55'
                        src={profileInfo.ptnImgUrl}
                        width='72'
                        srcFallback={defaultThumbnail}
                    />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                    }}>
                        {/*<Text as='span' textAlign='center' typo='font_detail_2M' color='color_text_neutral_3'>*/}
                        {/*    {profileInfo.metaTag}*/}
                        {/*</Text>*/}
                        <Text as='span' textAlign='center' typo='font_heading_4B' color='color_text_neutral_6'>
                            {profileInfo.ptnName}
                        </Text>
                    </div>
                </div>

                {profileInfo.barcodeYn === 'N' ? renderButtonGroup(false) : renderButtonGroup(true)}
            </div>
            {showSnackbar && (
                <Snackbar text={isLike === 'Y' ? '좋아하는 제휴사에 추가했어요' : '좋아하는 제휴사에서 제외했어요.'}/>
            )}
            <BS_Barcode isOpen={showBarcode} onToggle={(isOpen) => {
                setShowBarcode(isOpen)
            }} bottomSpace={'0'}/>
            <CommonPopup submitText={'확인'} description={'잠시 후 다시 시도해주세요.'} isOpen={showErrorPopup} onSubmit={() => {
                setShowErrorPopup(false)
            }}/>
        </>
    );
};

export default PartnerProfile;