import {Box, Button, List, TextSet} from "@lguuxe/2024_designsystem_uni";
import React, {useEffect} from "react";
import {TermsPersonalInfo} from "../../pages/Setting/types";

interface UseAgreeListSectionProps {
    termsPersonalInfoList: TermsPersonalInfo[],
    onTermClick: (term: TermsPersonalInfo) => void;
}

export const getGtmLocation = (termsId: string) => {
    switch (termsId) {
        case '150042':
            return '고객 혜택 제공을 위한 정보 수집 및 이용 동의'
        case '150043':
            return '혜택정보, 광고수신 동의'
        case '150044':
            return '위치기반 서비스 이용 약관'
        case '150045':
            return '서비스 사용을 위한 개인정보 수집 및 이용 동의'
        case '150046':
            return '개인정보 제3자 제공 동의'
        default:
            return ''
    }
}

const UseAgreeListSection: React.FC<UseAgreeListSectionProps> = ({termsPersonalInfoList, onTermClick}) => {
    const [activeTerm125003, setActiveTerm125003] = React.useState<boolean>(false);
    const termType125003 = termsPersonalInfoList.filter((term) => term.termsType === '125003');

    useEffect(() => {
        const term125001 = termsPersonalInfoList.find((term) => term.termsType === '125001');
        if (term125001) {
            setActiveTerm125003(term125001.termYn === 'Y');
        }
    }, [termsPersonalInfoList]);

    return (
        <div style={{paddingBottom: "12px"}}>
            <Box type="2_trbl">
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        title: '개인정보 이용 동의',
                    }}
                    weight="default"
                />
            </Box>
            <div>
                {termsPersonalInfoList
                    .sort((a, b) => parseInt(a.dpOrder) - parseInt(b.dpOrder))
                    .map((term) => (
                        <>
                            {term.termsType !== '125003' && (
                                <List
                                    key={term.termId}
                                    fill="none"
                                    isSlotClickable={true}
                                    items={[
                                        {
                                            leftSlot: (
                                                <TextSet
                                                    subComponent="Body_1"
                                                    text={{
                                                        title: <span>{term.termName}</span>,
                                                    }}
                                                    weight="bolder"
                                                />
                                            ),
                                            rightSlot: (
                                                <Button
                                                    as="button"
                                                    color="neutral"
                                                    fill="text"
                                                    size="large"
                                                    state="default"
                                                    rightIcon="chevron_right_small_line"
                                                    onClick={() => onTermClick(term)}
                                                    data-gtm-event-name={'all_click'}
                                                    data-gtm-event-category={'클릭'}
                                                    data-gtm-event-action={`설정|개인정보 이용${getGtmLocation(term.termId)} - 링크 클릭`}
                                                    data-gtm-event-label={`컨텐츠 : ${term.termName}`}
                                                    data-gtm-click-url={''}
                                                    data-gtm-click-location={`설정|개인정보 이용${getGtmLocation(term.termId)}`}
                                                    data-gtm-click-direction={'내부'}
                                                    data-gtm-click-text={term.termName}
                                                >
                                                    {term.termYn === 'Y' ? '동의함' : '미동의'}
                                                </Button>
                                            ),
                                        }
                                    ]}
                                    type="view"
                                    weight="bolder"
                                />
                            )}

                            {/* termType이 125001일 경우 125003을 아래에 렌더링 */}
                            {term.termsType === '125001' && termType125003.map((term125003) => (
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        backgroundColor: "#F3F5F6",
                                        padding: "6px 0",
                                        borderRadius: "12px",
                                        margin: "0 20px"
                                    }}
                                    key={term125003.termId}
                                >
                                    <List
                                        isSlotClickable={true}
                                        fill="none"
                                        items={[
                                            {
                                                leftSlot: [
                                                    <TextSet
                                                        subComponent="Body_1"
                                                        text={{
                                                            title: term125003.termName,
                                                        }}
                                                        weight="bolder"
                                                    />
                                                ],
                                                rightSlot: [
                                                    <Button
                                                        as="button"
                                                        color="neutral"
                                                        fill="text"
                                                        size="medium"
                                                        state="default"
                                                        rightIcon="chevron_right_small_line"
                                                        disabled={!activeTerm125003}
                                                        onClick={() => onTermClick(term125003)}
                                                        data-gtm-event-name={'all_click'}
                                                        data-gtm-event-category={'클릭'}
                                                        data-gtm-event-action={`설정|개인정보 이용|혜택정보, 광고수신 동의 - 링크 클릭`}
                                                        data-gtm-event-label={`컨텐츠 : ${term125003.termName}`}
                                                        data-gtm-click-url={''}
                                                        data-gtm-click-location={'설정|개인정보 이용|혜택정보, 광고수신 동의'}
                                                        data-gtm-click-direction={'내부'}
                                                        data-gtm-click-text={term125003.termName}
                                                    >
                                                        {term125003.termYn === 'Y' ? '동의함' : '미동의'}
                                                    </Button>
                                                ],
                                            }
                                        ]}
                                        type="view"
                                        weight="bolder"
                                    />
                                </Box>
                            ))}
                        </>
                    ))}
            </div>
        </div>
    )
}

export default UseAgreeListSection;