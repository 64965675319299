import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Button, Image} from "@lguuxe/2024_designsystem_uni";
import {useNavigate, useParams} from "react-router-dom";
import uptp_default from "../../images/default/default_Upltwopl_full.svg";
import ExImg from "../../images/default/default_Upltwopl_full.svg";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionList from "../../components/UPTP/SectionList";
import BoxHeaderInfo from "../../components/UPTP/BoxHeaderInfo";
import CommonCTAColumn from "../../components/Common/CommonCTAColumn";
import membershipsServiceApi from "../../api/gatewayApi/memberships-service";
import membershipsService from "../../api/gatewayApi/memberships-service";
import {initialProInfo, ProInfo} from "./types";
import MustCheckAccordion from "../../components/Setting/SectionAccordion";
import BS_Barcode from "../../components/Barcode/BS_Barcode";
import {sendEvent} from "../../api/nativeBridge";
import useLoading from "../../hooks/useLoading";
import {getItemByKey} from "../../utils/indexedDB";
import {useSnackbar} from "../../provider/SnackbarProvider";
import {useMessagePopup} from "../../provider/MessagePopupProvider";
import CommonPopup from "../../components/Common/CommonPopup";
import {ProConditionInfo} from "../Home/types";
import {brazeEventApi} from "../../api/brazeBridge";
import {gtmAffiliateCouponDownloadComp, gtmPageLoad} from "../../api/gtm";
import {formatDate} from "../../utils/dateUtiles";

export type ButtonState = {
    text: string | null;
    isActive: boolean;
}

export interface UptpDetailHandles {
    waitingPopupSuccess: (id: string) => void;
}

const UPTPDetail = forwardRef<UptpDetailHandles>((props, ref) => {
    const navigate = useNavigate();
    const {eventId} = useParams(); // useParams로 eventId를 가져옴
    const [proInfo, setProInfo] = useState<ProInfo>(initialProInfo);
    const [proConditionInfo, setProConditionInfo] = useState<ProConditionInfo>({conditionsYn: "", conditionsMsg: ""});
    const [timestamp, setTimestamp] = useState<string>("");
    const [isBarcodeOpen, setBarcodeOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {showSnackbar} = useSnackbar();
    const {showMessagePopup} = useMessagePopup();
    const [showCouponIssuePopup, setShowCouponIssuePopup] = useState<boolean>(false);
    const [buttonState, setButtonState] = useState<ButtonState>({text: "참여하기", isActive: false});

    useImperativeHandle(ref, () => ({
        async waitingPopupSuccess(id) {
            const ids = id.split("|");
            const params = {
                proId: ids[0],
                proCpnId: ids[1],
                cpnId: ids[2],
                os: await getItemByKey("platform"),
                cardLevel: await getItemByKey("cardLevel"),
                longTermCode: await getItemByKey("longTermCode"),
                youthYn: await getItemByKey("youthYn"),
                gender: await getItemByKey("gender") || "A"
            };

            const response = await membershipsService.postUPTPIssueCoupon(params, "v1");
            try {
                if (response.header.resultCode === "0000") {
                    // showSnackbar('쿠폰이 발급되었어요', "쿠폰함 바로가기", () => navigate('/my/coupon'));
                    setShowCouponIssuePopup(true);

                    // gtm
                    const cpnId = ids[2];
                    const result = proInfo.cpnInfoList?.find(cpn => cpn.cpnId === cpnId);
                    if (result && eventId) {
                        gtmAffiliateCouponDownloadComp(result.cpnName, '', '', '', `${formatDate(result.expStartDate)} - ${formatDate(result.expEndDate)}`, eventId, proInfo.proTitle);
                    }

                } else {
                    showMessagePopup(response.header.message);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }));

    useEffect(() => {
        if (!eventId) return;
        const fetchEventById = async () => {
            try {
                if (eventId) {
                    const getUPTPEvent = await membershipsServiceApi.getUPTPEvent(eventId, 'v1');
                    setProInfo(getUPTPEvent.body.proInfo);
                    setTimestamp(getUPTPEvent.header.timeStamp)
                    if (getUPTPEvent.body.proInfo.proType === "138002") { // 응모형일때만 호출
                        const param = {
                            proId: eventId,
                            os: await getItemByKey("platform"),
                            cardLevel: await getItemByKey("cardLevel"),
                            longTermCode: await getItemByKey("longTermCode"),
                            youthYn: await getItemByKey("youthYn"),
                            gender: await getItemByKey("gender") || "A"
                        }
                        const eventCheckRes = await membershipsServiceApi.getUPTPEventCheck(param, 'v1');
                        if (eventCheckRes.header.resultCode === "0000") {
                            setProConditionInfo(eventCheckRes.body.proConditionInfo);
                        }
                    }

                    const proType = getUPTPEvent.body.proInfo.proType;
                    const month = getUPTPEvent.header.timeStamp.substring(5, 7);
                    await gtmPageLoad({
                        url: `/uptp-detail/${eventId}`,
                        contentGroup: `혜택|유플투쁠|${proType === '138001' ? '쿠폰형' : proType === '138002' ? '응모형' : '바코드형'} 상세| ${month}월 ${getUPTPEvent.body.proInfo.proTitle}`,
                        moduleName: '',
                        title: getUPTPEvent.body.proInfo.proTitle,
                    });

                    await brazeEventApi.event_detail_page_enter(getUPTPEvent.body.proInfo.proTitle, eventId, 'N');
                }
            } catch (error) {
                console.error('Failed to fetch event by id:', error);
            } finally {
                setIsLoading(false);
                window.scrollTo(0, 0.1);

            }
            sendEvent("KAKAO_PIXEL", {param: "ParticipationEvent", tag: "Uplay"})
        };

        fetchEventById();
    }, [eventId]);

    useEffect(() => {
        if (proInfo.proType === "138002") {
            handleButtonState();
        }
    }, [proInfo, proConditionInfo]);

    const goEntry = () => {
        navigate("/uptp-entry", {state: proInfo});
    };

    const handleButtonState = () => {
        const currentDate = new Date(timestamp);
        const startDate = new Date(proInfo.proStartDate);
        const endDate = new Date(proInfo.proEndDate);

        if (currentDate < startDate) {
            const dayDifference = Math.ceil((startDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)); // 일 단위로 계산
            const hours = startDate.getHours().toString().padStart(2, '0');

            setButtonState({text: `D-${dayDifference} ${hours}부터 참여 가능`, isActive: false});
        } else if (currentDate > endDate) {
            setButtonState({text: "종료된 이벤트", isActive: false});
        } else if (proConditionInfo.conditionsYn === "N" || proConditionInfo.conditionsYn === "A") {
            setButtonState({text: proConditionInfo.conditionsMsg, isActive: false});
        } else {
            setButtonState({text: proConditionInfo.conditionsMsg, isActive: true});
        }
    }


    const renderBarcodeButton = () => {
        let handleBarcodeClick = () => {
            setBarcodeOpen(true)
        }

        const currentDate = new Date(timestamp);
        const startDate = new Date(proInfo.proStartDate);
        const endDate = new Date(proInfo.proEndDate);

        let buttonText = "U+멤버십바코드 보기";
        let disabled = false;
        let color = "secondary"
        if (currentDate < startDate) {
            const dayDifference = Math.ceil((startDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)); // 일 단위로 계산
            const hours = startDate.getHours().toString().padStart(2, '0');

            buttonText = `D-${dayDifference} ${hours}부터 확인 가능`;
            color = "neutral";
            disabled = false;
            handleBarcodeClick = () => {
            }
        } else if (currentDate > endDate) {
            buttonText = "종료된 이벤트";
            disabled = true;
        }

        return (
            <>
                <div style={{padding: '12px 24px 24px 24px'}}>
                    <Button
                        color={color}
                        fill='solid'
                        size='medium'
                        state='default'
                        style={{width: '100%'}}
                        onClick={handleBarcodeClick}
                        disabled={disabled}
                        data-gtm-event-name={'all_click'}
                        data-gtm-event-category={'클릭'}
                        data-gtm-event-action={'멤버십 바코드 보기 - 링크 클릭'}
                        data-gtm-event-label={`컨텐츠 : U+멤버십바코드 보기`}
                        data-gtm-click-url={''}
                        data-gtm-click-location={'멤버십 바코드 보기'}
                        data-gtm-click-direction={'내부'}
                        data-gtm-click-text={`U+멤버십바코드 보기`}
                    > {buttonText}
                    </Button>
                </div>
            </>
        )
    }
    const renderContentByProType = () => {
        switch (proInfo.proType) {
            case "138001": // 쿠폰형
                return (
                    <>
                        {proInfo.cpnInfoList &&
                            <SectionList proTarget={proInfo.proTarget} cpnInfoList={proInfo.cpnInfoList}
                                         proId={proInfo.proId} timestamp={timestamp}/>}
                        <BoxHeaderInfo title="이용 조건" content={proInfo.cpnUseTerms}/>
                        <BoxHeaderInfo title="이용 방법" content={proInfo.cpnUseMethod}/>
                        {/*<BoxHeaderInfo title="쿠폰 이용 기간" content={proInfo.cpnUseMethod}/>*/}
                        <div style={{margin: "8px 0"}}>
                            <CommonDivider/>
                        </div>
                        {proInfo.cpnPrecautions && <MustCheckAccordion items={[proInfo.cpnPrecautions]}/>}
                    </>
                );
            case "138002": // 응모형
                return (
                    <>
                        <BoxHeaderInfo title="혜택" content={proInfo.entBenefits}/>
                        <BoxHeaderInfo title="대상" content={proInfo.entUseTerms}/>
                        <BoxHeaderInfo title="이용 방법" content={proInfo.entUseMethod}/>
                        <BoxHeaderInfo title="당첨 확인" content={proInfo.entPrecautions}/>
                        <div style={{margin: "8px 0"}}>
                            <CommonDivider/>
                        </div>
                        {proInfo.entPrecautions && <MustCheckAccordion items={[proInfo.entPrecautions]}/>}
                    </>
                );
            case "138003": // 바코드형
                return (
                    <>
                        {renderBarcodeButton()}
                        <div style={{marginBottom: "8px"}}>
                            <CommonDivider/>
                        </div>
                        <BoxHeaderInfo title="혜택" content={proInfo.entBenefits}/>
                        <BoxHeaderInfo title="대상" content={proInfo.entUseMethod}/>
                        <BoxHeaderInfo title="이용 방법" content={proInfo.entUseMethod}/>
                        <div style={{margin: "8px 0"}}>
                            <CommonDivider/>
                        </div>
                        {proInfo.entPrecautions && <MustCheckAccordion items={[proInfo.entPrecautions]}/>}
                    </>
                );
            default:
                return <div>알 수 없는 프로모션 타입입니다.</div>;
        }
    };

    return (
        <>
            {useLoading(isLoading)}
            <div style={{position: 'fixed', width: '100%', zIndex: 300}}>
                {/* header */}
                <CommonLeftIconHeader title={proInfo.proTitle}/>
            </div>

            <div style={{marginTop: '3.5rem'}}>
                <div>
                    <Image
                        customClass="customClass"
                        height="auto"
                        src={proInfo.introImgUrl || uptp_default}
                        width="100%"
                        srcFallback={ExImg}
                    />
                </div>

                <div
                    style={{
                        padding: "12px 0 24px"
                    }}
                >
                    {renderContentByProType()}
                </div>
                {proInfo.proType === "138003" &&
                    <BS_Barcode isOpen={isBarcodeOpen} onToggle={(isOpen: boolean) => (setBarcodeOpen(isOpen))}/>}
                {proInfo.proType === "138002" && (
                    <CommonCTAColumn buttonText={buttonState.text} handleSummit={goEntry}
                                     isActive={buttonState.isActive}/>
                )}
                <CommonPopup submitText={'확인'} onSubmit={() => {
                    navigate('/my/coupon')
                }} isOpen={showCouponIssuePopup} description={'쿠폰이 발급되었어요'}
                             onClose={() => setShowCouponIssuePopup(false)}/>
            </div>
        </>
    );
});

export default UPTPDetail;
