import React, {useEffect, useRef, useState} from 'react';
import {Button, Image, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import bugapan4 from '../../images/icon/11_emoji/emoji_01.svg';
import attendanceIcon from '../../images/main/misson/attendance.svg';
import CommonDividerThin from '../Common/CommonDividerThin';
import {AttendanceInfo} from '../../pages/Mission/types';
import {useNavigate} from 'react-router-dom';
import {attendanceApi} from '../../api/gatewayApi/attendanceAndEvent';
import CommonPopupWithImage from '../Common/CommonImagePopup';
import attendanceComplete from '../../images/img/popup/attendance_completed.svg';
import entranceComplete from '../../images/img/popup/entrance_completed.svg';
import {addItem, getItemByKey} from "../../utils/indexedDB";
import {brazeEventApi} from "../../api/brazeBridge";
import useDebounce from "../../hooks/useDebounce";
import {gtmDailyCheckComp} from "../../api/gtm";

interface CalenderThisWeekProps {
    attendance: AttendanceInfo;
    weekOrMonth: 'week' | 'month';
}

interface Popup {
    specialDayEventPopup: boolean;
    attendancePopup: boolean;
    continueAttendancePopup: boolean;
    giftName: string;
    attendCntLimit: number;
    continueAttendance: number;
}

const initialPopup: Popup = {
    specialDayEventPopup: false,
    attendancePopup: false,
    continueAttendancePopup: false,
    giftName: '',
    attendCntLimit: 0,
    continueAttendance: 0,
}


const getWeekStartAndEnd = (today: Date) => {
    // 오늘 날짜의 요일을 가져옵니다. (0: 일요일, 1: 월요일, ..., 6: 토요일)
    const dayOfWeek = today.getDay();

    // 주의 시작일 (일요일)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - dayOfWeek);

    // 주의 종료일 (토요일)
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (6 - dayOfWeek));

    return {
        startOfWeek,
        endOfWeek,
    };
}
const timeFormat = (date: Date): string => {
    const currentTime = date.toISOString();
    return `${currentTime.split("T")[0]}`;
}

const Calender: React.FC<CalenderThisWeekProps> = ({attendance, weekOrMonth}) => {
    const [today, setToday] = useState<Date>(new Date());
    const [todayAttendance, setTodayAttendance] = useState<boolean>(true);
    const [attendDate, setAttendDate] = useState<number[]>([]);
    const [specialDate, setSpecialDate] = useState<number[]>([]);
    const [name, setName] = useState<string>('');
    const navigate = useNavigate();
    const [popup, setPopup] = useState<Popup>(initialPopup);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (attendance && attendance.yearMonth) {
            const year = parseInt(attendance.yearMonth.substring(0, 4), 10);
            const month = parseInt(attendance.yearMonth.substring(4, 6), 10);
            const day = parseInt(attendance.yearMonth.substring(6, 8), 10);
            // timeFormat시 -9시간 되어 날짜가 하루밀림 9시로 적용
            setToday(new Date(year, month - 1, day, 9));
            // setToday(new Date(2024, 9, 20));
        }
        const dates = attendance.attendCheckDateList.map(item => parseInt(item.attendCheckDate, 10));
        setAttendDate(dates);

        if (dates.includes(today.getDate())) {
            setTodayAttendance(true);
        } else {
            setTodayAttendance(false);
        }

        const specialDates = attendance.specialDateList.map(item => parseInt(item.specialDate, 10));
        setSpecialDate(specialDates);

        const fetchIndexedDb = async () => {
            addItem('attendanceCnt', attendance.attendCheckDateList.length);

            if (attendance.attendCheckDateList.length > 0) {
                const Day = attendance.attendCheckDateList[attendance.attendCheckDateList.length - 1]?.attendCheckDate;
                const [year, month] = timeFormat(today).split("-").slice(0, 2);
                addItem('lastAttendance', `${year}-${month}-${Day}`);
            }

            setName(await getItemByKey('customerName'));
        }
        fetchIndexedDb();
    }, [attendance]);

    const handleAttendance = useDebounce(() => {
        const fetch = async () => {
            const memberId = await getItemByKey('memberId');
            try {
                const request = {
                    memberId: memberId,
                    attendId: attendance.attendId,
                    attendName: attendance.attendName,
                }
                attendanceApi.postCheck(request, 'v1').then((response) => {
                    console.log('attendanceApi.postCheck response : ', response);
                    switch (response.header.resultCode) {
                        case '0000':
                            let isPopup = false;
                            attendance.giftList.map(item => {
                                if (item.giftType === 'A' && item.attendCntLimit === attendance.attendCheckDateList.length + 1) {
                                    setPopup({
                                        ...popup,
                                        continueAttendancePopup: true,
                                        giftName: item.giftName,
                                        attendCntLimit: item.attendCntLimit,
                                    });
                                    isPopup = true;
                                }
                                if (item.giftType === 'S' && specialDate.includes(today.getDate())) {
                                    setPopup({
                                        ...popup,
                                        specialDayEventPopup: true,
                                        giftName: item.giftName,
                                    });
                                    isPopup = true;
                                }
                            });

                            if (!isPopup) {
                                setPopup({
                                    ...popup,
                                    attendancePopup: true,
                                    // continueAttendance: getContinueAttendDay() + 1,
                                    continueAttendance: attendance.attendCheckDateList.length + 1,
                                });
                            }
                            setTodayAttendance(true);
                            setAttendDate([...attendDate, today.getDate()]);

                            addItem('attendanceCnt', attendance.attendCheckDateList.length + 1);
                            addItem('lastAttendance', timeFormat(today));
                            brazeEventApi.attendance_check_complete();
                            gtmDailyCheckComp();
                            break;
                        default:
                            break;
                    }
                });

            } catch (error) {
                console.log('attendanceApi.postCheck error : ', error);
            }
        };
        fetch();

    });

    const getContinueAttendDay = () => {
        let count = 0;
        let day = today.getDate();

        for (let i = attendDate.length - 1; i >= 0; i--) {
            if (day - attendDate[i] === 1) {
                count++;
                day--; // 다음날로 이동
            } else {
                break; // 연속되지 않으면 중단
            }
        }
        return count;
    }

    const generateWeekCalendar = () => {
        const days = [];
        const {startOfWeek, endOfWeek} = getWeekStartAndEnd(today);

        for (let i = 0; i < 7; i++) {
            const current = new Date(startOfWeek);
            current.setDate(startOfWeek.getDate() + i);
            const dNum = current.getDate();

            const isToday = current.toDateString() === today.toDateString();
            const isAttendance = attendDate.includes(dNum);
            const isPast = current < today;
            const isSpecial = specialDate.includes(dNum);

            let dayClass = isToday
                ? isAttendance
                    ? 'attendance'
                    : 'd_day'
                : isAttendance
                    ? 'attendance'
                    : isPast
                        ? 'back'
                        : '';

            if (isSpecial && !isAttendance) {
                dayClass = isPast ? 'special_back' : 'special';
            }

            days.push(
                <span key={i} className={dayClass}
                      style={{visibility: current.getMonth() === today.getMonth() ? 'visible' : 'hidden'}}>
                    {isToday && !isAttendance ? (
                        <span className='d_day'>
                            <span>{dNum}</span>
                        </span>
                    ) : (
                        dNum
                    )}
                </span>
            );
        }
        return days;
    };

    const generateMonthCalendar = () => {
        const calendar = [];
        let dNum = 1;
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1).getDay();
        const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        const rows = Math.ceil((firstDay + lastDate) / 7);

        for (let i = 0; i < rows; i++) {
            const week = [];
            for (let k = 0; k < 7; k++) {
                if ((i === 0 && k < firstDay) || dNum > lastDate) {
                    week.push(<span key={k}></span>);
                } else {
                    const isToday = dNum === today.getDate();
                    const isPast = dNum < today.getDate();
                    const isAttendance = attendDate.includes(dNum);
                    const isSpecial = specialDate.includes(dNum);
                    let dayClass = isToday
                        ? isAttendance
                            ? 'attendance'
                            : 'd_day'
                        : isAttendance
                            ? 'attendance'
                            : isPast
                                ? 'back'
                                : '';

                    if (isSpecial && !isAttendance) {
                        dayClass = isPast ? 'special_back' : 'special';
                    }

                    week.push(
                        <span key={k} className={dayClass}>
                        {isToday && !isAttendance ? (
                            <span className='d_day'>
                                <span>{dNum}</span>
                            </span>
                        ) : (
                            dNum
                        )}
                    </span>
                    );
                    dNum++;
                }
            }
            calendar.push(
                <div key={i} className='data_num'>
                    {week}
                </div>
            );
        }
        return calendar;
    };

    const handleMoreClick = () => {
        navigate('/mission/attendance');
    };

    const handleReceivedAlert = () => {
        navigate('/setting');
    };
    return (
        <>
            <div style={{padding: '24px 20px 12px'}}>
                <TextSet
                    subComponent='Heading_2'
                    text={{
                        title: (
                            <div style={{display: 'flex', alignItems: 'flex-end', gap: '8px'}}>
                                {weekOrMonth === 'week' ? (
                                    <span>
                                        오늘도 잊지말고
                                        <br/>
                                        출석하세요
                                        {/*<img*/}
                                        {/*    src={bugapan4}*/}
                                        {/*    alt="버튼 이미지"*/}
                                        {/*    style={{marginLeft: '5px', verticalAlign: 'middle', width: '22px'}}*/}
                                        {/*/>*/}
                                    </span>) : (<span>
                                {name}님
                                <br/>
                                    {/*<span style={{color: '#E6007E'}}>{attendDate.length}번</span>{' '}*/}
                                    <span
                                        style={{
                                            marginBottom: '5px',
                                            display: 'inline-flex',
                                            gap: '5px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        이번 달은 {attendDate.length}번 출석했어요
                                        <Image customClass='customClass' ratio='1:1' src={bugapan4} width='22'/>
                                    </span>
                            </span>)}
                            </div>
                        ),
                    }}
                    weight='default'
                />
            </div>

            <div className='Marketing_calendar'>
                <div className='data'>
                    {['일', '월', '화', '수', '목', '금', '토'].map((day) => (
                        <Text key={day} typo='font_body_3Sb' color='color_text_neutral_5'>
                            {day}
                        </Text>
                    ))}
                </div>

                <div style={{padding: '0 20px'}}>
                    <CommonDividerThin/>
                </div>

                {weekOrMonth === 'week' ? (
                    <>
                        <div className='data_num'>{generateWeekCalendar()}</div>

                        <div style={{padding: '0 20px'}}>
                            <CommonDividerThin/>
                        </div>
                    </>
                ) : (
                    <>
                        {generateMonthCalendar().map((week, index) => (
                            <React.Fragment key={index}>
                                {week}
                                <div style={{padding: '0 20px'}}>
                                    <CommonDividerThin/>
                                </div>
                            </React.Fragment>
                        ))}
                    </>
                )}

                <div className='data_list'>
                    <div className='attendance_day'>
                        <Image ratio='1:1' src={attendanceIcon} width='15'/>
                        <Text typo='font_detail_3M'>출석한 날</Text>
                    </div>
                    <div className='day'>
                        <span className='dot'></span>
                        <Text typo='font_detail_3M'>오늘</Text>
                    </div>
                    <div className='special_day'>
                        <span className='dot orange'></span>
                        <Text typo='font_detail_3M'>특별 보상일</Text>
                    </div>
                </div>
            </div>

            <div style={{padding: '24px 20px'}}>
                <Button
                    as='button'
                    color='secondary'
                    fill='solid'
                    size='medium'
                    state='default'
                    style={{width: '100%'}}
                    onClick={handleAttendance}
                    disabled={todayAttendance}
                >
                    {todayAttendance ? '출석체크 완료' : '출석 체크하기'}
                </Button>
                {weekOrMonth === 'week' && (<Button
                    as='button'
                    color='secondary'
                    fill='text'
                    size='medium'
                    state='default'
                    rightIcon='chevron_right_small_line'
                    style={{width: '100%', padding: '13px 0'}}
                    onClick={handleMoreClick}
                    data-gtm-event-name={'all_click'}
                    data-gtm-event-category={'클릭'}
                    data-gtm-event-action={'미션|출석체크 - 링크 클릭'}
                    data-gtm-event-label={`컨텐츠 : 출석체크 더보기`}
                    data-gtm-click-url={''}
                    data-gtm-click-location={'미션|출석체크'}
                    data-gtm-click-direction={'내부'}
                    data-gtm-click-text={`더보기`}
                >
                    <Text typo='font_label_2Sb' color='color_text_neutral_3'>
                        더보기
                    </Text>
                </Button>)}
            </div>

            <CommonPopupWithImage
                title='출석 완료!'
                // description={popup.continueAttendance + '일째 연속 출석했어요.'}
                description={`이번 달 ${popup.continueAttendance}일 출석했어요`}
                imageUrl={attendanceComplete}
                onSubmit={() => setPopup({...popup, attendancePopup: false})}
                submitText='확인'
                isOpen={popup.attendancePopup}
            />

            <CommonPopupWithImage
                title={`${popup.giftName} 응모 완료!`}
                description={`${today.getDate()}일에 출석하여 이벤트에 자동 응모됐어요.\\n\\n*당첨 안내를 받으려면 알림을 켜주세요.`}
                imageUrl={entranceComplete}
                onSubmit={() => setPopup({...popup, specialDayEventPopup: false})}
                submitText='확인'
                cancelText='알림 받기'
                onClose={handleReceivedAlert}
                isOpen={popup.specialDayEventPopup}
            />

            <CommonPopupWithImage
                title={`${popup.giftName} 응모 완료!`}
                description={`${popup.attendCntLimit}회 출석하여 이벤트에 자동 응모됐어요.\\n\\n*당첨 안내를 받으려면 알림을 켜주세요.`}
                imageUrl={attendanceComplete}
                onSubmit={() => setPopup({...popup, continueAttendancePopup: false})}
                submitText='확인'
                cancelText='알림 받기'
                onClose={handleReceivedAlert}
                isOpen={popup.continueAttendancePopup}
            />
        </>
    );
};

export default Calender;