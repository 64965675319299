import React, {useEffect, useRef, useState} from 'react';
import './App.css';

import PartnerDetail from './pages/Partner/PartnerDetail';
import Search from './pages/Search/Search';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import ResultDetail from './pages/Search/ResultDetail';
import Notice from './pages/Notice/Notice';
import Vipkok from './pages/Vipkok/Vipkok';
import VipkokDetail from './pages/Vipkok/VipkokDetail';
import BS_CouponBarcode from './components/Barcode/BS_CouponBarcode';
import HomeBenefitPopup, {HomeBenefitPopupHandles} from './pages/Home/HomeBenefitPopup';
import IdentityVerification from './pages/IdentityVerification/IdentityVerification';
import PhoneVerification, {PhoneVerificationHandles} from './pages/IdentityVerification/PhoneVerification';
import Mission from './pages/Mission/Mission';
import MissionAttendance from './pages/Mission/MissionAttendance';
import UPTP, {UptpHandles} from './pages/UPTP/UPTP';
import UPTPDetail, {UptpDetailHandles} from './pages/UPTP/UPTPDetail';
import My from './pages/My/My';
import MyGrade from './pages/My/MyGrade';
import MyBenefit from './pages/My/MyBenefit';
import Promotion from "./pages/Badge/Promotion";
import Entire from './pages/Entire/Entire';
import MyBadge from "./pages/Badge/MyBadge";
import GiftBadge from "./pages/Badge/GiftBadge";
import MyCoupon from './pages/My/MyCoupon';
import MyCouponDetail from './pages/My/MyCouponDetail';
import Partners from "./pages/Partner/Partners";
import MyLikedPartners from "./pages/My/MyLikedPartners";
import {addItem, getItemByKey, initDB, removeItemByKey} from "./utils/indexedDB";
import Agreements, {AgreementHandles} from "./pages/Agreements/Agreements";
import TagManager from 'react-gtm-module';
import {gtmCommonComp, gtmUserInfo} from "./api/gtm";

import {sendEventReturn, sendEventReturnToAndroid, sendEventReturnToIOS} from "./api/nativeBridge";
import ResultMore from "./pages/Search/ResultMore";
import UPTPEntry from "./pages/UPTP/UPTPEntry";
import RepresentativeTerm from "./pages/IdentityVerification/RepresentativeTerm";
import PartnerMap from "./pages/Partner/PartnerMap";
import Pop from "./pages/Entire/pop/Pop";
import PopTerms, {PopTermHandles} from "./pages/Entire/pop/PopTerms";
import PopInput from "./pages/Entire/pop/PopInput";
import Setting, {SettingHandles} from "./pages/Setting/Setting";
import Quit, {QuitHandles} from "./pages/Setting/Quit";
import ModifyUserInfo from "./pages/Setting/ModifyUserInfo";
import AppVersion from "./pages/Setting/AppVersion";
import BenefitGuide from "./pages/BenefitGuide/BenefitGuide";
import Announcement from "./pages/Entire/Help/Announcement";
import AnnouncementDetail from "./pages/Entire/Help/AnnouncementDetail";
import Faq from "./pages/Entire/Help/Faq";
import FaqDetail from "./pages/Entire/Help/FaqDetail";
import CustomerAsk from "./pages/Entire/Help/CustomerAsk";
import CustomerAskWrite from "./pages/Entire/Help/CustomerAskWrite";
import RewardAsk from "./pages/Entire/Help/RewardAsk";
import UseTerms, {UseTermRef} from "./pages/Entire/Help/UseTerms";
import PrivacyPolicy from "./pages/Entire/Help/PrivacyPolicy";
import Opensource from "./pages/Setting/OpenSource";

import otherApi from "./api/gatewayApi/other";
import {decrypt, generateMCP_TR_KEY} from "./api/authFunctions";
import CommonPopup from "./components/Common/CommonPopup";
import Event from "./pages/Entire/Event/Event";
import MobilePayment from "./pages/MobilePayment/MobilePayment";
import MobilePaymentSetting, {PaymentSettingHandles} from "./pages/MobilePayment/MobilePaymentSetting";
import MobilePaymentLimit, {PaymentLimitHandles} from "./pages/MobilePayment/MobilePaymentLimit";
import EventResult from "./pages/Entire/Event/EventResult";
import paymentApi from "./api/gatewayApi/payment";
import {settingApi} from "./api/gatewayApi/settingAndTerms";
import useLandingPage from "./hooks/useLandingPage";
import useDevicePlatform from "./hooks/useDevicePlatform";
import UMembershipMyDatagift from './pages/MyDataGift/UMembershipMyDatagift';

import ScrollToTop from "./provider/ScrollToTop";
import MobilePaymentResult from "./pages/MobilePayment/MobilePaymentResult";
import {datadogRum} from "@datadog/browser-rum";
import {ENVIROMENT, fetchAuth} from "./api/api";
import RepresentativeTermDetail from "./pages/IdentityVerification/RepresentativeTermDetail";
import Update, {UpdateHandles} from "./pages/Agreements/Update";
import {callCheckPassword, handleSendEvent} from "./utils/helper";
import {useMessagePopup} from "./provider/MessagePopupProvider";
import {NavigationBarHandles} from "./components/Common/NavigationBar";

(window as any).digitalData = {};

TagManager.initialize({
    gtmId: 'GTM-MQRHVPWZ'
})

if (ENVIROMENT === 'prod') {
    datadogRum.init({
        applicationId: '65a493bf-57f9-42de-81d4-4f3b7a33ee0b',
        clientToken: 'pub0385564d3106780e4a942c99249beb85',
        site: 'datadoghq.com',
        // service: 'my-web-application',
        env: ENVIROMENT,
        // version: '1.0.0',
        sessionSampleRate: 10,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        enablePrivacyForActionName: true,
    });
}


const App: React.FC = () => {
    const navigate = useNavigate();
    const {landingPage} = useLandingPage();
    const homeBenefitPopupRef = useRef<HomeBenefitPopupHandles>(null);
    const SettingRef = useRef<SettingHandles>(null);
    const PaymentSettingRef = useRef<PaymentSettingHandles>(null);
    const PaymentLimitRef = useRef<PaymentLimitHandles>(null);
    const AgreementRef = useRef<AgreementHandles>(null);
    const quitRef = useRef<QuitHandles>(null);
    const phoneVerificationRef = useRef<PhoneVerificationHandles>(null);
    const uptpDetailRef = useRef<UptpDetailHandles>(null);
    const uptpRef = useRef<UptpHandles>(null);
    const useTermRef = useRef<UseTermRef>(null);
    const updateRef = useRef<UpdateHandles>(null);
    const popTermRef = useRef<PopTermHandles>(null);
    const navigationBarRef = useRef<NavigationBarHandles>(null);
    const location = useLocation();
    const {showMessagePopup} = useMessagePopup();

    const [myLgIdPopup, setMyLgIdPopup] = useState(false);
    const [passwordErrorPopup, setPasswordErrorPopup] = useState({show: false, message: ''});
    const [mobilePayRequestPopup, setMobilePayRequestPopup] = useState({
        show: false,
        payShopName: '',
        payMoney: '',
        otpId: ''
    });
    const webPlatformInfo = useDevicePlatform();

    const fetchUserInfo = async () => {
        if (process.env.REACT_APP_ENV_MODE === 'local') {
            const ctn = await getItemByKey('ctn')
            if (ctn !== undefined && ctn !== '00000000000') {
                if (homeBenefitPopupRef.current) {
                    console.log("triggerFetchSession in App.tsx")
                    homeBenefitPopupRef.current.triggerFetchSession();
                }
            } else {
                console.log('@@@@@@@@@@@@@@Default unLogin user')
                await addItem("platform", webPlatformInfo)
                await addItem('appVersion', '10.01.01')
                await addItem('telcoCode', 'L')
                await addItem('osVersion', '14')
                await addItem('ctn', '00000000000')
                await addItem('model', 'SM-F731N')
                if (homeBenefitPopupRef.current) {
                    console.log("triggerFetchSession in App.tsx")
                    homeBenefitPopupRef.current.triggerFetchSession();
                }
                await addItem('memberId', '')
            }
        } else {
            try {
                console.log('@@@@call DEVICE_SYSTEM_INFO && GET_MEMBER_ID')
                try {
                    sendEventReturnToIOS('GET_MEMBER_ID', {})
                    sendEventReturnToIOS('DEVICE_SYSTEM_INFO', {});
                    sendEventReturnToIOS('GET_AD_AGREE', {})
                } catch (error) {
                    console.error('error in sendEventReturnToIOS DEVICE_SYSTEM_INFO', error);
                }
                try {
                    sendEventReturnToAndroid('GET_MEMBER_ID', {})
                    sendEventReturnToAndroid('DEVICE_SYSTEM_INFO', {});
                    sendEventReturnToAndroid('GET_AD_AGREE', {})
                } catch (error) {
                    console.error('error in sendEventReturnToAndroid DEVICE_SYSTEM_INFO', error);
                }
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        }
    };

    useEffect(() => {
        initDB();
        const startApp = async () => {
            try {
                if (process.env.REACT_APP_ENV_MODE === 'local') {
                    await fetchUserInfo()
                }
            } catch (error) {
                console.error(error);
            } finally {
                try {
                    try {
                        sendEventReturnToIOS('INITIAL_LOADING_FINISHED', {});
                    } catch (error) {
                        console.error('error in sendEventReturnToIOS DEVICE_SYSTEM_INFO', error);
                    }
                    try {
                        sendEventReturnToAndroid('INITIAL_LOADING_FINISHED', {});
                    } catch (error) {
                        console.error('error in sendEventReturnToAndroid DEVICE_SYSTEM_INFO', error);
                    }
                } catch (error) {
                    console.error('Error fetching user info:', error);
                }
            }
        }
        startApp()

    }, []);

    const timeFormat = (date: Date): string => {
        const currentTime = date.toISOString();
        const offset = new Date().getTimezoneOffset() * -1; // Timezone offset in minutes (KST is UTC+9)
        const offsetHours = Math.floor(offset / 60);
        const offsetMinutes = offset % 60;
    
        const formattedOffset = `${offsetHours >= 0 ? "+" : "-"}${String(Math.abs(offsetHours)).padStart(2, "0")}:${String(offsetMinutes).padStart(2, "0")}`;
    
        return `${currentTime.split(".")[0]}${formattedOffset}`;
    }

    console.log(`api start in ${process.env.NODE_ENV} env and ${process.env.REACT_APP_ENV_MODE} MODE`)
    useEffect(() => {
        (window as any).onExecuteCallback = async (jsonString: string) => {
            try {
                console.log(jsonString)
                jsonString = jsonString.replace(/\n/g, '\\n');
                const jsonObject = JSON.parse(jsonString);
                console.log('web : Received object from native:', jsonObject);

                if (jsonObject.type === 'DEVICE_SYSTEM_INFO') {
                    const deviceInfo = jsonObject.param
                    console.log(deviceInfo)
                    await addItem('ctn', deviceInfo.ctn_info)
                    if (await getItemByKey('first_loading') === undefined || await getItemByKey('first_loading') === null) {
                        await addItem('first_loading', deviceInfo.first_loading)
                    }
                    await addItem('platform', deviceInfo.os_info === 'AND' ? 'AOS' : 'IOS')
                    if (deviceInfo.os_info === 'AND') {
                        const telcoCode = deviceInfo.tel_info === "LG U+" ? "L" : "O"
                        await addItem('telcoCode', telcoCode)
                    }
                    await addItem('osVersion', deviceInfo.os_version)
                    await addItem('appVersion', deviceInfo.version_info)
                    await addItem('model', deviceInfo.dev_model);
                    await addItem('adid', deviceInfo.adid)
                    if (homeBenefitPopupRef.current) {
                        console.log("triggerFetchSession in App.tsx")
                        homeBenefitPopupRef.current.triggerFetchSession(true);
                    }
                }

                if (jsonObject.type === 'GET_MEMBER_ID') {
                    const data = jsonObject.param
                    console.log('web : memberId:', data.memberId);
                    if (data.memberId) await addItem('memberId', data.memberId)
                    else await addItem('memberId', '')
                }

                if (jsonObject.type === 'SET_APP_LOCK') {
                    const data = jsonObject.param
                    if (SettingRef.current) {
                        SettingRef.current.setAppLock(data.enable)
                    }
                }

                if (jsonObject.type === 'SET_BIO_AUTH') {
                    const data = jsonObject.param
                    if (SettingRef.current) {
                        SettingRef.current.setBIO(data.enable)
                    }
                }

                if (jsonObject.type === 'GET_BIO_AUTH') {
                    const data = jsonObject.param
                    if (SettingRef.current) {
                        SettingRef.current.getBIO(data.enable)
                    }
                }

                if (jsonObject.type === 'GET_APP_LOCK') {
                    const data = jsonObject.param
                    if (SettingRef.current) {
                        SettingRef.current.getAppLock(data.enable)
                    }
                }

                if (jsonObject.type === 'INITIAL_LOADING_FINISHED') {
                    const data = jsonObject.param
                    console.log('web : LOADING_FINISHED:', data);
                    await fetchUserInfo();
                    // 앱 첫 구동시 바코드 정보 삭제
                    removeItemByKey('barcode');
                    removeItemByKey('barcodeTime');
                }

                if (jsonObject.param.action === 'keypress' && jsonObject.param.detail === 'back') {
                    console.log('web : back key pressed');

                    const event = new CustomEvent('keypressBack', {detail: {}});
                    window.dispatchEvent(event)

                    // if (homeBenefitPopupRef.current) {
                    //     console.log("triggerShowAddPopup in App.tsx")
                    //     homeBenefitPopupRef.current.triggerShowAddPopup();
                    if (navigationBarRef.current) {
                        navigationBarRef.current.triggerKeypressBack()
                    } else if (AgreementRef.current) {
                        AgreementRef.current.triggerKeypressBack()
                    } else if (useTermRef.current) {
                        useTermRef.current.triggerKeypressBack()
                    } else if (SettingRef.current) {
                        SettingRef.current.triggerKeypressBack()
                    } else {
                        navigate(-1)
                    }
                }

                if (jsonObject.type === 'GET_AD_AGREE') {
                    const data = jsonObject.param
                    console.log('web : GET_AD_AGREE:', data);
                    if (data.agree) await addItem('adAgreeYn', data.agree === true ? 'Y' : 'N');
                }

                if (jsonObject.type === 'SET_PASSWORD') {
                    const data = jsonObject.param
                    const resultType = data.result
                    if (resultType === "00") {
                        const telcoCode = await getItemByKey('telcoCode');
                        if (telcoCode === 'L') {
                            try {
                                const lockDisable = await paymentApi.setLockDisable({}, 'v1');
                                if (lockDisable.header.resultCode === "0000") {
                                    const paySeq = await paymentApi.getPaySeq('v1')
                                    if (paySeq.header.resultCode === "0000") {
                                        const encSeq = paySeq.body.encSeq
                                        const postPassword = await paymentApi.postPassword({
                                            encSeq: encSeq,
                                            passwordSha: '',
                                            newPasswordSha: data.password
                                        }, 'v1')
                                        if (postPassword.header.resultCode === "0000") {
                                            await sendEventReturn("SAVE_PASSWORD", {password: data.password})
                                        } else {
                                            setPasswordErrorPopup({
                                                message: postPassword.header.resultCode + postPassword.header.message,
                                                show: true
                                            })
                                            await sendEventReturn("SAVE_PASSWORD", {password: ''})
                                        }
                                    } else {
                                        setPasswordErrorPopup({
                                            message: paySeq.header.resultCode + paySeq.header.message,
                                            show: true
                                        })
                                        await sendEventReturn("SAVE_PASSWORD", {password: ''})
                                    }
                                } else {
                                    setPasswordErrorPopup({
                                        message: lockDisable.header.resultCode + lockDisable.header.message,
                                        show: true
                                    })
                                    await sendEventReturn("SAVE_PASSWORD", {password: ''})
                                }
                            } catch (error) {
                                await sendEventReturn("SAVE_PASSWORD", {password: ''})
                                console.error("Error SET_PASSWORD detail:", error);
                            }
                        } else {
                            //타사
                            await sendEventReturn("SAVE_PASSWORD", {password: data.password})
                        }
                    }
                }

                if (jsonObject.type === 'CHANGE_PASSWORD') {
                    const data = jsonObject.param
                    const resultType = data.result
                    if (resultType === "00") {
                        const telcoCode = await getItemByKey('telcoCode');
                        if (telcoCode === 'L') {
                            try {
                                if (!data.prePassword || data.prePassword === '0') {
                                    const lockDisable = await paymentApi.setLockDisable({}, 'v1');
                                }
                                const paySeq = await paymentApi.getPaySeq('v1')
                                if (paySeq.header.resultCode === "0000") {
                                    const encSeq = paySeq.body.encSeq
                                    const postPassword = await paymentApi.postPassword({
                                        encSeq: encSeq,
                                        passwordSha: data.prePassword,
                                        newPasswordSha: data.password
                                    }, 'v1')
                                    if (postPassword.header.resultCode === "0000") {
                                        await sendEventReturn("SAVE_PASSWORD", {password: data.password})
                                        gtmCommonComp('휴대폰결제 비밀번호 변경')
                                    } else {
                                        setPasswordErrorPopup({
                                            message: postPassword.header.resultCode + postPassword.header.message,
                                            show: true
                                        })
                                        await sendEventReturn("SAVE_PASSWORD", {password: ""})
                                    }
                                } else {
                                    setPasswordErrorPopup({
                                        message: paySeq.header.resultCode + paySeq.header.message,
                                        show: true
                                    })
                                    await sendEventReturn("SAVE_PASSWORD", {password: ""})
                                }
                            } catch (error) {
                                console.log("SAVE_PASSWORD error", error)
                                await sendEventReturn("SAVE_PASSWORD", {password: ""})
                            }
                        } else {
                            await sendEventReturn("SAVE_PASSWORD", {password: data.password})
                        }
                        if (PaymentSettingRef.current) {
                            PaymentSettingRef.current.getPassword(data.password)
                            return;
                        }
                        if (SettingRef.current) {
                            SettingRef.current.handleChangePassword()
                        } else { // 비밀번호 5회 틀렸을때 휴대폰 인증 페이지에서 돌아온다
                            navigate(-1)
                        }

                    }
                }


                if (jsonObject.type === 'CHECK_PASSWORD') {
                    const data = jsonObject.param
                    const resultType = data.result
                    const auth = data.authType
                    const page = data.page
                    const password = data.password

                    if (page === "barcode") {
                        if (resultType === '00') {
                            console.log('passwordBarcode ')
                            const event = new CustomEvent('passwordBarcode', {detail: {password: password}});
                            window.dispatchEvent(event)
                        } else {
                            const event = new CustomEvent('failPassword', {detail: {resultType: resultType}});
                            window.dispatchEvent(event)
                        }
                    }
                    if (page === "barcodeTerm") {
                        if (resultType === '00') {
                            const event = new CustomEvent('passwordBarcodeTerm', {detail: {password: password}});
                            window.dispatchEvent(event)
                        } else {
                            const event = new CustomEvent('failPassword', {detail: {resultType: resultType}});
                            window.dispatchEvent(event)
                        }
                    }
                    if (page === "paymentLimit") { // 한도 변경에서 사용
                        if (PaymentLimitRef.current) {
                            PaymentLimitRef.current.handleCheckPassword(password, resultType === '00')
                            return;
                        }
                    }
                    if (page === 'paymentLimit/term') {
                        if (resultType === '00') {
                            if (PaymentLimitRef.current) {
                                PaymentLimitRef.current.handleCheckPasswordTerm(password)
                                return;
                            }
                        }
                    }
                    if (page === 'mobile-pay/setting|payBlock') {
                        if (PaymentSettingRef.current) {
                            PaymentSettingRef.current.handleCheckPassword(resultType === '00', 'payBlock')
                            return;
                        }
                    }
                    if (page === 'mobile-pay/setting|autoPayBlock') {
                        if (PaymentSettingRef.current) {
                            PaymentSettingRef.current.handleCheckPassword(resultType === '00', 'autoPayBlock')
                            return;
                        }
                    }
                    if (page === 'paymentSetting/payBlockTerm') {
                        if (PaymentSettingRef.current) {
                            PaymentSettingRef.current.handleCheckPasswordTerm(resultType === '00', 'payBlock')
                            return;
                        }
                    }
                    if (page === 'paymentSetting/autoPayBlockTerm') {
                        if (PaymentSettingRef.current) {
                            PaymentSettingRef.current.handleCheckPasswordTerm(resultType === '00', 'autoPayBlock')
                            return;
                        }
                    }
                    if (page.startsWith('paymentRequest')) {
                        const otpId = page.split('-')[1]
                        const paySeq = await paymentApi.getPaySeq('v1')
                        if (paySeq.header.resultCode === "0000") {
                            const payConfirm = await paymentApi.postPayConfirm({
                                otpId: otpId,
                                encSeq: paySeq.body.encSeq,
                                passwordSha: password,
                                appResult: '00',
                                authType: '01'
                            }, 'v1')
                            if (paySeq.header.resultCode === "0000") {
                                setPasswordErrorPopup({message: "거래완료", show: true})
                            }
                        } else {
                            setPasswordErrorPopup({
                                message: paySeq.header.resultCode + paySeq.header.message,
                                show: true
                            })
                        }
                    }
                    if (page === "modifyInfo") {
                        if (resultType === '00') {
                            if (SettingRef.current) {
                                SettingRef.current.handleModifyInfo()
                            }
                        }
                    }
                }

                if (jsonObject.type === 'PASSWORD') {
                    const data = jsonObject.param
                    const password = data.password
                    const page = data.page
                    if (PaymentSettingRef.current) {
                        PaymentSettingRef.current.getPassword(password)
                        return;
                    }

                    if (PaymentLimitRef.current) {
                        PaymentLimitRef.current.handleCheckPassword(password, true)
                        return;
                    }

                    if (page.startsWith('paymentRequest')) {
                        // 휴대폰 결제 취소시 호출한다.
                        const otpId = page.split('-')[1]
                        const paySeq = await paymentApi.getPaySeq('v1')
                        if (paySeq.header.resultCode === "0000") {
                            const payConfirm = await paymentApi.postPayConfirm({
                                otpId: otpId,
                                encSeq: paySeq.body.encSeq,
                                passwordSha: password,
                                appResult: '01',
                                authType: '00'
                            }, 'v1')
                            if (paySeq.header.resultCode === "0000") {
                                setPasswordErrorPopup({message: "취소완료", show: true})
                            }
                        } else {
                            setPasswordErrorPopup({
                                message: paySeq.header.resultCode + paySeq.header.message,
                                show: true
                            })
                        }
                    }
                }

                if (jsonObject.type === 'CALL_LOGIN') {
                    const data = jsonObject.param
                    switch (data.rt) {
                        case '00000':
                            const fetchDas = async () => {
                                try {
                                    const tcpTrKey = await generateMCP_TR_KEY();
                                    const res = await otherApi.getDas({
                                        ssoKey: data.sso,
                                        lgId: data.userId
                                    }, 'v1', tcpTrKey);
                                    if (res.header.resultCode === '0000') {
                                        const name = await decrypt(tcpTrKey, res.body.name);
                                        const ctn = res.body.ctn;
                                        const info = {
                                            name: name,
                                            birthday: res.body.birthday,
                                            gender: res.body.gender,
                                            nation: res.body.nation,
                                            telcoCode: res.body.carrier,
                                            phoneNo: ctn.length === 12 ? ctn.slice(0, 3) + ctn.slice(4) : ctn,
                                            userCI: res.body.ci,
                                            dasKey: res.body.dasKey,
                                            dasSsoKey: res.body.dasSsoKey,
                                        }

                                        if (info.dasKey) { // myLg 회원가입 시 개인정보가 잘못내려오는 경우
                                            const platform = await getItemByKey('platform');
                                            if (platform !== 'IOS' && platform !== 'ios') {
                                                const telcoCode = await getItemByKey('telcoCode');
                                                const ctn = await getItemByKey('ctn');
                                                if (info.telcoCode !== telcoCode || res.body.ctn !== ctn) {
                                                    showMessagePopup('MY LG ID로 가입할 수 없는 회원이예요. 휴대폰번호로 재인증해주세요.');
                                                    return;
                                                }
                                            }
                                        }

                                        addItem('loginType','MYLGID');
                                        addItem('signupDate',timeFormat(new Date()));
                                        navigate('/agreements/join', {state: info});
                                    } else {
                                        setMyLgIdPopup(true);
                                    }
                                } catch (error) {
                                    console.log('Error fetching DAS API:', error);
                                    setMyLgIdPopup(true);
                                }
                            }
                            fetchDas();
                            break;
                        case '00014': // 14세 미만
                        default:
                            setMyLgIdPopup(true);
                            break;
                    }
                }

                if (jsonObject.type === 'CALL_CERTIFICATION') {
                    const data = jsonObject.param
                    if (data && data.target === 'certification' && data.isMember) {
                        navigate('/phone-certification?target=app')
                    }
                    if (data && data.target === 'certification' && !data.isMember) {
                        navigate('/phone-certification?target=web')
                    }
                }

                if (jsonObject.type === 'LANDING_PAGE') {
                    const data = jsonObject.param
                    console.log('LANDING_PAGE data : ', data)

                    if (data.targetUrl === 'mobile-pay/request') {
                        if (homeBenefitPopupRef.current) {
                            homeBenefitPopupRef.current.stopPayExistPopup()
                        }
                        const response = await paymentApi.getPayRequest({}, 'v1')
                        if (response.header.resultCode === "0000") {
                            const data = response.body
                            setMobilePayRequestPopup({
                                show: true,
                                payShopName: data.payShopName,
                                payMoney: data.payMoney,
                                otpId: data.otpId
                            })
                        } else {
                            showMessagePopup(`${response.header.message}`)
                        }
                        return;
                    }

                    let urlParam = '';
                    if (data.utm_medium !== '') {
                        urlParam += 'utm_medium=' + data.utm_medium + '&';
                    }
                    if (data.utm_source !== '') {
                        addItem('utmSource', data.utm_source);
                        urlParam += 'utm_source=' + data.utm_source + '&';
                    }
                    if (data.utm_campaign !== '') {
                        urlParam += 'utm_campaign=' + data.utm_campaign + '&';
                    }
                    if (data.utm_term !== '') {
                        urlParam += 'utm_term=' + data.utm_term + '&';
                    }
                    if (data.utm_content !== '') {
                        addItem('utmContent', data.utm_content);
                        urlParam += 'utm_content=' + data.utm_content + '&';
                    }
                    if (urlParam.length > 0) {
                        urlParam = urlParam.slice(0, -1);
                    }
                    let tmpParam = data.targetParam || ''
                    landingPage(data.targetType, data.targetUrl + '|' + tmpParam, navigate, '이벤트 상세', urlParam);
                }

                if (jsonObject.type === 'FCM_TOKEN') {
                    const data = jsonObject.param
                    if (AgreementRef.current) {
                        AgreementRef.current.triggerHandleJoin(data.fcmToken)
                    }
                }
                if (jsonObject.type === 'REFRESH_TOKEN') {
                    const data = jsonObject.param
                    if (await getItemByKey('memberId')) {
                        await addItem('pushRegId', data.newToken)
                        const response = await settingApi.putPushReg({pushRegId: data.newToken}, 'v1');
                        if (response.header.resultCode === '0000') {
                            await addItem('sendTokenYn', 'Y')
                        } else {
                            await addItem('sendTokenYn', 'N')
                        }
                    }
                }
                if (jsonObject.type === 'ACTION_EVENT') {
                    const data = jsonObject.param
                    if (data.action === 'resume') {

                        const mcpTrKey = generateMCP_TR_KEY()
                        const responseAuth = await fetchAuth(mcpTrKey);
                        const token = responseAuth.body.token;
                        await addItem('sessionKey', token);
                        const platform = await getItemByKey('platform');
                        handleSendEvent(platform, 'SET_AUTHORIZATION', {
                            authorization: `${token}`,
                        });

                        const event = new CustomEvent('resume', {detail: {}});
                        window.dispatchEvent(event)

                        console.log(`resume navigate : ${window.location.pathname + window.location.search}`);
                        navigate(window.location.pathname + window.location.search, {replace: true});
                    }
                }

                if (jsonObject.type === 'WAITING_LINE_POPUP') {
                    const data = jsonObject.param
                    if (data.result === '00') {

                    } else if (data.result === '01') {

                    }
                }

                if (jsonObject.type === 'GET_GRANT_LOCATION') {
                    const data = jsonObject.param
                    const ptnId = data.ptnId;
                    if (data.agree) {
                        if (ptnId) {
                            navigate('/map', {state: {ptnId: ptnId}});
                        } else {
                            navigate('/map');
                        }
                    } else {
                        const event = new CustomEvent('triggerGpsPopup');
                        window.dispatchEvent(event)
                    }

                }
                if (jsonObject.type === 'CALL_LOCATION_POPUP') {
                    // 동의 여부와 상관없이 /map 으로 보냄. 현재 위치 못받아오면 default 위치 보여줌
                    const ptnId = jsonObject.param.ptnId;
                    if (ptnId) {
                        navigate('/map', {state: {ptnId: ptnId}});
                    } else {
                        navigate('/map');
                    }
                }

                if (jsonObject.type === 'RESET_LOGIN_INFO') {
                    if (SettingRef.current) {
                        SettingRef.current.handleResetLoginInfo();
                    }
                    if (quitRef.current) {
                        quitRef.current.handleResetLoginInfo();
                    }
                    if (updateRef.current) {
                        updateRef.current.handleRemove();
                    }
                }

                if (jsonObject.type === 'OPEN_NOTIFICATION') {
                    const data = jsonObject.param
                    if (SettingRef.current) {
                        SettingRef.current.getNotification(data.result, data.target);
                    }
                }

                if (jsonObject.type === 'GET_NOTIFICATION') {
                    const data = jsonObject.param
                    if (SettingRef.current) {
                        SettingRef.current.getNotification(data.result);
                    }
                }

                if (jsonObject.type === 'IPIN_RESULT') {
                    const data = jsonObject.param

                    const name = await decrypt(data.MCP_TR_KEY, data.Name);
                    const birth = await decrypt(data.MCP_TR_KEY, data.Birth);

                    const resultObj: any = {
                        ipinAuthId: data.IPINAuthNum,
                        name: name,
                        birthday: birth,
                        gender: data.Gender,
                        nation: data.Nation,
                        code: data.Code,
                    };

                    if (phoneVerificationRef.current) {
                        phoneVerificationRef.current.handleIpinVerification(resultObj);
                    }
                }

                if (jsonObject.type === 'WAITING_LINE_POPUP') {
                    const data = jsonObject.param
                    const target = data.target;
                    if (data.result === '00') { // 대기열 성공
                        if (target === 'detail') {
                            navigate(`/uptp-detail/${data.id}`);
                            return;
                        }

                        // 쿠폰형일 경우
                        if (uptpDetailRef.current) {
                            uptpDetailRef.current.waitingPopupSuccess(data.id);
                        }

                        // if (homeBenefitPopupRef.current) {
                        //     homeBenefitPopupRef.current.waitingPopupSuccess(data.id);
                        // }
                        // if (uptpRef.current) {
                        //     uptpRef.current.waitingPopupSuccess(data.id);
                        // }
                    }
                }

                if (jsonObject.type === 'CLOSE_NOTI') {
                    if (homeBenefitPopupRef.current) {
                        homeBenefitPopupRef.current.triggerSetVipYn();
                    }
                }

            } catch (error) {
                console.log('web : JSON parsing error:', error);
            }
        };

        gtmUserInfo();
        // (window as any).onExecuteCallback(JSON.stringify({ action: 'keypress', detail: 'back' }));

        return () => {
            delete (window as any).onExecuteCallback;
        };

    }, []);

    return (
        <div className='app-container'>
            <div className='main-content'>
                <ScrollToTop/>
                <Routes>
                    //TODO index.html 추후 삭제
                    <Route path='/index.html'
                           element={<HomeBenefitPopup navigationBarRef={navigationBarRef} ref={homeBenefitPopupRef}/>}/>
                    <Route path='/'
                           element={<HomeBenefitPopup navigationBarRef={navigationBarRef} ref={homeBenefitPopupRef}/>}/>
                    <Route path='/partners' element={<Partners/>}/>
                    <Route path='/partners/:ptnId' element={<PartnerDetail/>}/>
                    <Route path='/vip-kok' element={<Vipkok/>}/>
                    <Route path='/vip-kok-detail/:ptnId' element={<VipkokDetail/>}/>
                    <Route path='/u-membership-my-datagift' element={<UMembershipMyDatagift/>}/>
                    <Route path='/identity-verification' element={<IdentityVerification/>}/>
                    <Route path='/phone-certification' element={<PhoneVerification ref={phoneVerificationRef}/>}/>
                    <Route path='/representative' element={<RepresentativeTerm/>}/>
                    <Route path='/representative/term' element={<RepresentativeTermDetail/>}/>
                    <Route path='/search' element={<Search/>}/>
                    <Route path='/result-detail' element={<ResultDetail/>}/>
                    <Route path='/result-more' element={<ResultMore/>}/>
                    <Route path='/notice' element={<Notice/>}/>
                    <Route path='/Barcode/BS_CouponBarcode' element={<BS_CouponBarcode/>}/>
                    <Route path='/agreements/:type' element={<Agreements ref={AgreementRef}/>}/>
                    <Route path='/uptp' element={<UPTP ref={uptpRef}/>}/>
                    <Route path='/uptp-detail/:eventId' element={<UPTPDetail ref={uptpDetailRef}/>}/>
                    <Route path='/uptp-entry' element={<UPTPEntry/>}/>
                    <Route path='/promotion' element={<Promotion/>}/>
                    <Route path='/my' element={<My navigationBarRef={navigationBarRef}/>}/>
                    <Route path='/my/grade' element={<MyGrade/>}/>
                    <Route path='/my/benefit' element={<MyBenefit/>}/>
                    <Route path='/mission' element={<Mission navigationBarRef={navigationBarRef}/>}/>
                    <Route path='/mission/attendance'
                           element={<MissionAttendance navigationBarRef={navigationBarRef}/>}/>
                    <Route path='/entire' element={<Entire navigationBarRef={navigationBarRef}/>}/>
                    <Route path='/my/badge' element={<MyBadge/>}/>
                    <Route path='/my/badge/gift' element={<GiftBadge/>}/>
                    <Route path='/my/coupon' element={<MyCoupon/>}/>
                    <Route path='/my/coupon/:cpnId' element={<MyCouponDetail/>}/>
                    <Route path='/my/partners' element={<MyLikedPartners/>}/>
                    <Route path='/map' element={<PartnerMap/>}/>
                    <Route path='/setting' element={<Setting ref={SettingRef}/>}/>
                    <Route path='/setting/quit' element={<Quit ref={quitRef}/>}/>
                    <Route path='/setting/modify-user-info' element={<ModifyUserInfo/>}/>
                    <Route path='/setting/app-version' element={<AppVersion/>}/>
                    <Route path='/setting/license' element={<Opensource/>}/>
                    <Route path='/pop' element={<Pop/>}/>
                    <Route path='/pop/terms' element={<PopTerms ref={popTermRef}/>}/>
                    <Route path='/pop/input' element={<PopInput/>}/>ㅋ
                    <Route path='/benefit-guide' element={<BenefitGuide/>}/>
                    <Route path='/help/announcement' element={<Announcement/>}/>
                    <Route path='/help/announcement/:id' element={<AnnouncementDetail/>}/>
                    <Route path='/help/faq' element={<Faq/>}/>
                    <Route path='/help/faq/:id' element={<FaqDetail/>}/>
                    <Route path='/help/ask' element={<CustomerAsk/>}/>
                    <Route path='/help/ask/reward' element={<RewardAsk/>}/>
                    <Route path='/help/ask/write' element={<CustomerAskWrite/>}/>
                    <Route path='/help/terms' element={<UseTerms ref={useTermRef}/>}/>
                    <Route path='/help/private-policy' element={<PrivacyPolicy/>}/>
                    <Route path='/event' element={<Event/>}/>
                    <Route path='/mobile-pay' element={<MobilePayment/>}/>
                    <Route path='/mobile-pay/setting' element={<MobilePaymentSetting ref={PaymentSettingRef}/>}/>
                    <Route path='/mobile-pay/limit' element={<MobilePaymentLimit ref={PaymentLimitRef}/>}/>
                    <Route path='/event/result/:eventId' element={<EventResult/>}/>
                    <Route path='/benefit-guide/:id' element={<BenefitGuide/>}/>
                    <Route path='/mobile-pay/:status/:id' element={<MobilePaymentResult/>}/>
                    <Route path='/mobile-pay/:status' element={<MobilePaymentResult/>}/>
                    <Route path='/update' element={<Update ref={updateRef}/>}/>
                    {/* test page*/}
                    {/*<Route path='/auth' element={<AuthTest/>}/>*/}
                    {/*<Route path='/test' element={<Test/>}/>*/}
                    {/*<Route path='/test2' element={<TestAuth/>}/>*/}
                </Routes>
            </div>

            <CommonPopup isOpen={passwordErrorPopup.show} description={passwordErrorPopup.message} submitText={'확인'}
                         onSubmit={() => setPasswordErrorPopup({...passwordErrorPopup, show: false})}
            />
            <CommonPopup isOpen={myLgIdPopup} description={'일시적으로 MY LG ID 인증을 할 수 없어요. \n휴대폰번호로 인증해 주세요.'}
                         submitText={'휴대폰번호로 인증'}
                         onSubmit={() => {
                             setMyLgIdPopup(false)
                             navigate('/phone-certification')
                         }} cancelText={'취소'}
                         onClose={() => {
                             setMyLgIdPopup(false)
                         }}/>

            <CommonPopup isOpen={mobilePayRequestPopup.show} title={'휴대폰 결제 요청'}
                         description={`${mobilePayRequestPopup.payShopName}에서 ${mobilePayRequestPopup.payMoney}원을 결제할까요?`}
                         submitText={'확인'}
                         onSubmit={() => {
                             callCheckPassword(`paymentRequest-${mobilePayRequestPopup.otpId}`)
                             setMobilePayRequestPopup({show: false, payShopName: '', payMoney: '', otpId: ''})
                         }}
                         cancelText={'닫기'}
                         onClose={() => {
                             sendEventReturn('PASSWORD', {page: `paymentRequest-${mobilePayRequestPopup.otpId}`})
                             setMobilePayRequestPopup({show: false, payShopName: '', payMoney: '', otpId: ''})
                         }}/>

        </div>
    );
};

export default App;
