import React, {useEffect, useState} from "react";
import {Chip} from "@lguuxe/2024_designsystem_uni";
import {Tag} from "../../pages/Home/types";
import {addItem, getItemByKey} from "../../utils/indexedDB";


interface SectionChipProps {
    Tags: Tag[];
    handleChipClick?: (tagId: string, tagName: string) => void;
    selectedCategoryId?: string;  // 외부에서 주입된 선택된 categoryId
    gtmType?: string; // gtm 구분을 위한 type
}

const SectionChip: React.FC<SectionChipProps> = ({Tags, handleChipClick, selectedCategoryId, gtmType}) => {
    const [selectedChip, setSelectedChip] = useState<string | null>(null);
    // Set the initial selected chip to the first item in the Tags array
    const setChip = async () => {
        // const badgeChip = await getItemByKey("badgeChip")
        if (selectedCategoryId) {
            setSelectedChip(selectedCategoryId);
        // } else if(badgeChip) {
        //     setSelectedChip(badgeChip)
        } else if (Tags.length > 0) {
            setSelectedChip(Tags[0].tagId); // 기본값 설정
        }
    }

    useEffect(() => {
        setChip()
    }, [selectedCategoryId, Tags]);

    const handleClick = async (id: string, name: string) => {
        if (handleChipClick) {
            // await addItem("badgeChip", id)
            handleChipClick(id, name)
        }
        setSelectedChip(id)
    }
    const selectedStyle = {
        minWidth: "auto",
        backgroundColor: "#414762",
        color: "#fff",
    };

    return (
        <div
            style={{
                padding: "0",
                display: "flex",
                gap: "6px",
                overflow: "auto"
            }}
        >
            {Tags.map((tag) => (
                <Chip.Filter
                    key={tag.tagId}
                    color="neutral"
                    isSelected={selectedChip === tag.tagId}
                    fill={selectedChip === tag.tagId ? 'solid' : 'outline'}
                    label={tag.tagName}
                    onClick={() => handleClick(tag.tagId, tag.tagName)}
                    size="medium"
                    style={selectedChip === tag.tagId ? selectedStyle : {minWidth: "auto"}}
                    data-gtm-event-name={gtmType !== undefined ? 'all_click' : undefined}
                    data-gtm-event-category={gtmType !== undefined ? '클릭' : undefined}
                    data-gtm-event-action={gtmType !== undefined ? `${gtmType}|카테고리 - 링크 클릭` : undefined}
                    data-gtm-event-label={gtmType !== undefined ? `컨텐츠 : ${tag.tagName}` : undefined}
                    data-gtm-click-url={gtmType !== undefined ? '' : undefined}
                    data-gtm-click-location={gtmType !== undefined ? `${gtmType}|카테고리 블록` : undefined}
                    data-gtm-click-direction={gtmType !== undefined ? '내부' : undefined}
                    data-gtm-click-text={gtmType !== undefined ? `${tag.tagName}` : undefined}
                />
            ))}
        </div>
    );
};

export default SectionChip;
