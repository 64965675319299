import React, {useState} from 'react';
import {List} from "@lguuxe/2024_designsystem_uni";
import {BenefitPtn, LongTerm, VipPtn} from "../../pages/UPTP/types";
import ExImg from "../../images/default/default thumbnail.svg";
import {useNavigate} from "react-router-dom";
import {sendEventReturn} from "../../api/nativeBridge"; // 기본 이미지

interface SectionHeadingTabListProps {
    items: Array<BenefitPtn | VipPtn | LongTerm>;
    selectedTab: string;
}

const SectionHeadingTabList: React.FC<SectionHeadingTabListProps> = ({items, selectedTab}) => {
    const [imageErrorIndexes, setImageErrorIndexes] = useState<Set<number>>(new Set());
    const navigate = useNavigate()
    const handleClick = (item: BenefitPtn | VipPtn | LongTerm) => {
        if (selectedTab === '1') {
            if ('ptnName' in item) {
                navigate(`/partners/${item.ptnId}`);
            }
        } else if (selectedTab === '2') {
            if ('ptnName' in item) {
                navigate(`/vip-kok-detail/${item.ptnId}`);
            }
        } else if (selectedTab === '3') {
            if ('serviceLink' in item && item.serviceLink) { // LongTerm 타입인지와 serviceLink 존재 여부 확인
                sendEventReturn('EXTERNAL_CALL', {
                    type: '1',
                    url: item.serviceLink
                });
            }
        }
    };

    return (
        <>
            <List
                fill="none"
                items={items.map((item, index) => ({
                    image: imageErrorIndexes.has(index) ? ExImg : ('ptnLogoImgUrl' in item ? item.ptnLogoImgUrl : item.serviceImgUrl),
                    text: {
                        eyebrow: (
                            <>
                                {'ptnName' in item ? item.ptnName : item.serviceName}
                                {
                                    'categoryName' in item && item.categoryName &&
                                    <>
                                        <span className='bar'></span>
                                        <span style={{maxWidth: '60px'}}>{item.categoryName}</span>
                                    </>
                                }
                            </>
                        ),
                        title: item.benefitSummary,
                    },
                    imageSrcFallback: ExImg,
                    linkIconOnlyId: "empty",
                    onClick: () => handleClick(item),
                    dataAttributes: {
                        'data-gtm-event-name': 'all_click',
                        'data-gtm-event-category': '클릭',
                        'data-gtm-event-action': `전체혜택|${selectedTab === '1' ? '기본혜택' : selectedTab === '2' ? 'VIP콕' : '장기고객 혜택'} - 링크 클릭`,
                        'data-gtm-event-label': `컨텐츠 : ${item.benefitSummary}`,
                        'data-gtm-click-url': '',
                        'data-gtm-click-location': `전체혜택|${selectedTab === '1' ? '기본혜택' : selectedTab === '2' ? 'VIP콕' : '장기고객 혜택'}`,
                        'data-gtm-click-direction': '내부',
                        'data-gtm-click-text': `${item.benefitSummary}`,
                        'data-gtm-affiliate-id': 'ptnId' in item ? item.ptnId : '',
                        'data-gtm-affiliate-name': 'ptnName' in item ? item.ptnName : '',
                    }
                }))}
                name="list_name"
                size="medium"
                type="view"
                weight="bolder"
            />
        </>
    );
}

export default SectionHeadingTabList;
