import {Button, Image, List, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import React, {CSSProperties} from 'react';
import defaultThumbnail from '../../images/default/default thumbnail.svg';
import {useNavigate} from 'react-router-dom';
import {PartnerItem} from "../../pages/Partner/types";
import {CLICK_LOCATION, CLICK_TEXT, EVENT_ACTION} from "../../utils/const";

interface PartnerSliderProps {
    partnerList: PartnerItem[];
    // isMoreButton?: boolean;
}

const PartnerCarousel: React.FC<PartnerSliderProps> = ({partnerList}) => {
    const navigate = useNavigate();

    const handlePartnerClick = (partnerId: string) => {
        navigate(`/partners/${partnerId}`);
    }

    const handleMore = () => {
        navigate('/my/partners');
    };

    const renderNoDataMessage = () => (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '24px 0',
        }}>
            <Text typo='font_detail_1M' color='color_text_neutral_3' textAlign='center'>
                관심있는 제휴사에<br/>하트를 눌러 보세요
            </Text>
        </div>
    );
    const renderDataList = () => (
        <List
            horizontalItemWidth='86px'
            style={{
                gap: '0.75rem',
                padding: "1rem 1.25rem 1rem 1.25rem",
                width: "calc(100% - 2.5rem)",
            }}
            fill='none'
            name='list_name'
            size='large'
            type='view'
            weight='default'
            items={partnerList.map((item, index) => ({
                onClick: () => handlePartnerClick(item.ptnId),
                leftSlot: [
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '6px',
                        }}
                    >
                        <Image
                            src={item.ptnLogoImgUrl}
                            alt='제휴이미지'
                            width='56'
                            ratio='1:1'
                            // height='auto'
                            rounded={54.86}
                            srcFallback={defaultThumbnail}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                minWidth: '84px',
                            }}
                        >
                            <Text typo='font_body_1B' color='color_text_neutral_6' style={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 1, // 최대 2줄로 설정
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "normal",
                            }}>
                                {item.ptnName}
                            </Text>
                        </div>
                    </div>

                ],
                dataAttributes: {
                    'data-gtm-event-name': 'all_click',
                    'data-gtm-event-category': '클릭',
                    'data-gtm-event-action': `즐겨찾기 제휴사 - 링크 클릭`,
                    'data-gtm-event-label': `컨텐츠 : ${item.ptnName}`,
                    'data-gtm-click-url': '',
                    'data-gtm-click-location': `즐겨찾기 제휴사`,
                    'data-gtm-click-direction': '내부',
                    'data-gtm-click-text': `${item.ptnName}`,
                    'data-gtm-affiliate-id': item.ptnId,
                    'data-gtm-affiliate-name': item.ptnName,
                }
            }))}
        />
    );
    const renderMoreButton = () => (
        <div style={styles.buttonContainer}>
            <Button
                color='neutral'
                rightIcon='chevron_right_small_line'
                size='large'
                fill='text'
                style={styles.button}
                onClick={handleMore}
                data-gtm-event-name={'all_click'}
                data-gtm-event-category={'클릭'}
                data-gtm-event-action={`${CLICK_LOCATION.FAVORITE_PARTNER} - ${EVENT_ACTION.LINK_CLICK}`}
                data-gtm-event-label={`컨텐츠 : ${CLICK_TEXT.FAVORITE_PARTNER_MORE}`}
                data-gtm-click-url={''}
                data-gtm-click-location={`${CLICK_LOCATION.FAVORITE_PARTNER}`}
                data-gtm-click-direction={'내부'}
                data-gtm-click-text={`${CLICK_TEXT.FAVORITE_PARTNER_MORE}`}
            >
                더보기
            </Button>
        </div>
    );

    return (
        <div>
            <TextSet
                subComponent='Heading_4'
                text={{
                    title: (
                        <>
                            <Text typo='font_heading_4B'>
                                좋아하는 제휴사
                                <Text typo='font_heading_4B' color='color_text_primary'>
                                    &nbsp;{partnerList.length}
                                </Text>
                            </Text>
                        </>
                    ),
                }}
                blockSpacing={{top: '24px'}}
                isIndent={true}

            />
            {partnerList.length === 0 ? renderNoDataMessage() : renderDataList()}
            {/*{partnerList.length === 0 ? null : renderMoreButton()}*/}
            {renderMoreButton()}
        </div>
    );
};

const styles: { [key: string]: CSSProperties } = {
    buttonContainer: {
        textAlign: 'center',
        borderTop: '1px solid #E7EBEE',
        marginTop: '12px',
    },
    button: {
        padding: '14px 0',
        width: '100%',
    },
};

export default PartnerCarousel;
