import React, {useEffect, useState} from 'react';
import CommonDivider from '../../components/Common/CommonDivider';
import NavigationBar from '../../components/Common/NavigationBar';
import TitleHeader from '../../components/Common/TitleHeader';
import Calender from '../../components/Mission/Calender';
import MissionList from '../../components/Mission/MissionList';
import {AttendanceInfo, initialAttendance} from './types';
import {attendanceApi} from '../../api/gatewayApi/attendanceAndEvent';
import RewordBlock from '../../components/Mission/RewordBlock';
import OtherApi from '../../api/gatewayApi/other';
import {MissionReward} from "../My/types";
import {handlePromiseResult} from '../../api/api'
import {addItem, getItemByKey} from "../../utils/indexedDB";
import {brazeEventApi} from "../../api/brazeBridge";
import {useNavigate} from "react-router-dom";
import {gtmPageLoad} from "../../api/gtm";

interface MissionProps {
    navigationBarRef: React.Ref<any>;
}

const Mission: React.FC<MissionProps> = ({navigationBarRef}) => {
    const [attendance, setAttendance] = useState<AttendanceInfo>(initialAttendance);
    const [missionReward, setMissionReward] = useState<MissionReward>({rewardPoint: '0'});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [memberStatus, setMemberStatus] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchIndexedDb = async () => {
            const memberId = await getItemByKey('memberId')
            if (!memberId) {
                navigate('/')
            }
            setMemberStatus(await getItemByKey('memberStatus'));
        }

        const fetchData = async () => {
            try {
                const [attendance, reward] = await Promise.allSettled([
                    attendanceApi.get('v1'),
                    OtherApi.getMissionReward('v1'),
                ]);
                const attendanceData = handlePromiseResult(attendance, initialAttendance);
                setAttendance(attendanceData);
                const rewardData = handlePromiseResult(reward, {rewardPoint: '0'});
                setMissionReward(rewardData);
                brazeEventApi.mission_tab_enter(rewardData?.rewardPoint);
                await addItem('rewardPoint', rewardData?.rewardPoint);
            } catch (error) {
                console.log('Failed to fetch Mission :', error);
            } finally {
                // setIsLoading(false);
                await gtmPageLoad({
                    url: '/mission',
                    contentGroup: '미션|출석체크',
                    moduleName: '특별 미션|리워드|적립',
                    pageTitle: '미션'
                });
            }
        };
        fetchIndexedDb();
        fetchData();
    }, []);

    useEffect(() => {
        const handleBeforeUnload = () => {
            // 이탈 시 API 호출
            // brazeEventApi.mission_tab_exit(missionReward.rewardPoint);
        };

        return () => {
            handleBeforeUnload();
        };
    }, [navigate]);
    return (
        <>
            {/*{useLoading(isLoading)}*/}
            <div style={{paddingBottom: '96px'}}>
                <TitleHeader title='미션' isSetting={false}/>

                <Calender attendance={attendance} weekOrMonth={'week'}/>
                <CommonDivider/>

                 {/*타사 비노출*/}
                {/*{memberStatus !== '5' && <>*/}
                {/*    <MissionList/>*/}
                {/*    <CommonDivider/>*/}
                {/*</>}*/}

                {/* ----- 미션 블럭 --- */}
                <RewordBlock missionReward={missionReward}/>

                <NavigationBar selectedItem='mission' ref={navigationBarRef}/>
            </div>
        </>
    );
};

export default Mission;
