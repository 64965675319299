// PartnerList.tsx
import {IconButton, Image, List, Tag, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import React, {useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {PartnerItem, TotalPartners} from '../../pages/Partner/types';
import emptyPartnerImg from '../../images/img/empty/empty_partner.svg';
import defaultThumbnail from '../../images/default/default thumbnail.svg';
import {getTagInfoByBenefitCode} from "../../utils/helper";
import {partnerApi} from "../../api/gatewayApi/partnerAndAffiliate-service";
import CommonPopup from "../Common/CommonPopup";
import {useSnackbar} from "../../provider/SnackbarProvider";
import {formatLikeNum} from "../../utils/currencyUtils";
import { brazeEventApi } from '../../api/brazeBridge';

interface PartnerListProps {
    partners: TotalPartners;
    categoryName: string;
    onClickLike?: (ptnId: string, isLike: boolean) => void;
    type?: string;
    isUsable?: boolean;
}

const PartnerList: React.FC<PartnerListProps> = ({partners, onClickLike, categoryName, type, isUsable}) => {
    const navigate = useNavigate();
    const [showLikeErrorPopup, setShowLikeErrorPopup] = React.useState(false);
    const [showDislikeErrorPopup, setShowDislikeErrorPopup] = React.useState(false);
    const {showSnackbar} = useSnackbar();

    const lastClickRef = useRef<{ ptnId: string, timestamp: number }>({ptnId: '', timestamp: 0});

    const fetchLike = async (partner: PartnerItem, nowIsLike: boolean) => {
        try {
            const requestParam = {
                regYn: nowIsLike ? 'N' : 'Y',
            }
            partnerApi.putPartnersLikeById(partner.ptnId, requestParam, 'v1').then((res) => {
                if (res.header.resultCode !== '0000') {
                    nowIsLike ? setShowDislikeErrorPopup(true) : setShowLikeErrorPopup(true)
                    onClickLike && onClickLike(partner.ptnId, !nowIsLike);
                    return
                }

                if (nowIsLike) {
                    const cateNm = categoryName === '전체' ? '' : categoryName;
                    brazeEventApi.my_preferred_affiliate_cancel(partner.ptnName, partner.ptnId,  partner.ptnCateName || cateNm || "");
                    showSnackbar('좋아하는 제휴사에서 제외했어요.');
                } else {
                    const cateNm = categoryName === '전체' ? '' : categoryName;
                    brazeEventApi.favorite_click(partner.ptnName, partner.ptnId, partner.ptnCateName || cateNm || "");
                    showSnackbar('좋아하는 제휴사에 추가했어요.');
                }
            });
        } catch (error) {
            console.error('Failed to fetch :', error);
        }
    }


    const handlePartnerClick = (partner: PartnerItem) => {
        if (type) {
            navigate(`/vip-kok-detail/${partner.ptnId}`);
        } else {
            navigate(`/partners/${partner.ptnId}`);
        }
    };

    const handleLike = async (partner: PartnerItem, nowIsLike: boolean) => {
        const currentTime = new Date().getTime();
        const ptnId = partner.ptnId;

        if (lastClickRef.current.ptnId === ptnId && currentTime - lastClickRef.current.timestamp < 500) {
            return; // 클릭 무시
        }
        // 마지막 클릭 기록 업데이트
        lastClickRef.current = {ptnId, timestamp: currentTime};
        // api와는 별개로 좋아요 누르면 화면이 변경되도록 한다.
        // api 오류시에는 오류 팝업을 띄워주고 다시 시도하도록 한다.
        onClickLike && await onClickLike(ptnId, nowIsLike);
        fetchLike(partner, nowIsLike);
    };

    const renderNoPartner = () => (
        <div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '88px 0',
            }}>
                <Image src={emptyPartnerImg} alt='placeholder' width='120px' ratio='1:1'/>
                <div style={{
                    paddingTop: '20px',
                }}>
                    <Text typo='font_detail_1M' color='color_text_neutral_3' textAlign='center'>
                        제휴사가 없어요
                    </Text>
                </div>
            </div>
        </div>
    );

    const renderPartnerList = () => (
        <List
            type='view'
            fill='none'
            id='partnerList'
            isSlotClickable={true}
            items={partners.partnerList.map((partner) => ({
                id: `${partner.ptnId}`,
                fillLeftSlot: true,
                leftSlot: (
                    <div
                        style={{
                            display: 'flex', alignItems: 'center', cursor: 'pointer', flexGrow: 1,
                        }}
                        onClick={() => handlePartnerClick(partner)}
                    >
                        <Image
                            src={partner.ptnLogoImgUrl}
                            ratio='1:1'
                            width='48px'
                            rounded={'50%'}
                            srcFallback={defaultThumbnail}
                            // 클릭 이벤트 제거 (상위 div에 이미 onClick 있음)
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '0.5rem',
                                alignItems: 'flex-start',
                                marginLeft: '1rem',
                            }}
                        >
                            <TextSet
                                subComponent='Body_1'
                                text={{
                                    eyebrow: (
                                        <>
                                            <div
                                                style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    maxWidth: '100px',
                                                    display: 'inline-block',
                                                }}
                                            >
                                                <span>{partner.ptnName}</span>
                                            </div>
                                            {/*{categoryName && <>*/}
                                            {/*    <span className='bar'></span>*/}
                                            {/*    <span style={{maxWidth: '60px'}}>{categoryName}</span>*/}
                                            {/*</>}*/}
                                        </>
                                    ),
                                    title: (
                                        <Text
                                            style={{
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'normal',
                                                WebkitLineClamp: 2,
                                            }}
                                        >
                                            {partner.benefitSummary}
                                        </Text>
                                    ),
                                }}
                            />
                            <Tag
                                item={(() => {
                                    const specialCodes = ["127001", "127002", "127003", "127004"];
                                    const additionalCodes = ["127005", "127006", "127007"];

                                    const hasAllSpecialCodes = specialCodes.every(code =>
                                        partner.benefitTagList.some(benefit => benefit.benefitCode === code)
                                    );

                                    // 특별한 코드가 모두 존재하면 '모두' 태그 추가
                                    let filteredTags = [];
                                    if (hasAllSpecialCodes) {
                                        filteredTags.push({
                                            label: '모든등급',
                                            size: 'xSmall' as const, // 'xSmall'로 명시적으로 설정
                                            style: 'solid' as const,
                                            colorToken: 'default',
                                        });
                                    } else {
                                        // 특별한 코드가 모두 없으면, 기존 코드 매핑
                                        filteredTags = partner.benefitTagList
                                            .filter(benefit => specialCodes.includes(benefit.benefitCode))
                                            .map(benefit => {
                                                const tagInfo = getTagInfoByBenefitCode(benefit.benefitCode);
                                                return {
                                                    label: tagInfo.label,
                                                    size: 'xSmall' as const, // 타입 명시
                                                    style: 'solid' as const,
                                                    colorToken: tagInfo.colorToken,
                                                };
                                            });
                                    }

                                    // 추가 코드 127005, 127006은 항상 개별적으로 표시
                                    const additionalTags = partner.benefitTagList
                                        .filter(benefit => additionalCodes.includes(benefit.benefitCode))
                                        .map(benefit => {
                                            const tagInfo = getTagInfoByBenefitCode(benefit.benefitCode);
                                            return {
                                                label: tagInfo.label,
                                                size: 'xSmall' as const, // 타입 명시
                                                style: 'solid' as const,
                                                colorToken: tagInfo.colorToken,
                                            };
                                        });

                                    return [...filteredTags, ...additionalTags]; // 결합된 태그 리스트 반환
                                })()}
                            />
                        </div>
                    </div>
                ),
                rightSlot: (
                    onClickLike &&
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '49px'}}>
                        <IconButton
                            key={`icon-${partner.ptnId}`}
                            icon='favorite_line'
                            size='large'
                            fill='icon'
                            state='default'
                            isSelected={partner.isLike === 'Y'}
                            selectable
                            onClick={() => handleLike(partner, partner.isLike === 'Y')}
                        />
                        <TextSet subComponent={'Body_2'} text={{title: formatLikeNum(partner.likeNum)}}/>
                    </div>
                ),
                dataAttributes: {
                    'data-gtm-event-name': 'all_click',
                    'data-gtm-event-category': '클릭',
                    // 'data-gtm-event-action': `${categoryName}|사용가능체크${isUsable ? 'Y' : 'N'}|리스트 - 링크 클릭`,
                    'data-gtm-event-action': isUsable === undefined ? `${categoryName}|리스트 - 링크 클릭` : `${categoryName}|사용가능체크${isUsable ? 'Y' : 'N'}|리스트 - 링크 클릭`,
                    'data-gtm-event-label': `컨텐츠 : ${partner.benefitSummary}`,
                    'data-gtm-click-url': '',
                    // 'data-gtm-click-location': `${categoryName}|사용가능체크${isUsable ? 'Y' : 'N'}|리스트`,
                    'data-gtm-click-location': isUsable === undefined ? `${categoryName}|리스트` : `${categoryName}|사용가능체크${isUsable ? 'Y' : 'N'}|리스트`,
                    'data-gtm-click-direction': '내부',
                    'data-gtm-click-text': partner.benefitSummary,
                    'data-gtm-affiliate-id': partner.ptnId,
                    'data-gtm-affiliate-name': partner.ptnName,
                }
            }))}
        />
    );

    return (
        <>
            {partners?.totalCount === '0' ? renderNoPartner() : renderPartnerList()}
            <CommonPopup submitText={'확인'} isOpen={showLikeErrorPopup} onSubmit={() => setShowLikeErrorPopup(false)}
                         description={'좋아요 등록에 실패했습니다. 다시 시도해주세요.'}/>
            <CommonPopup submitText={'확인'} isOpen={showDislikeErrorPopup}
                         onSubmit={() => setShowDislikeErrorPopup(false)}
                         description={'좋아요 취소에 실패했습니다. 다시 시도해주세요.'}/>
        </>
    );
};

export default PartnerList;
