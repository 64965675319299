import {Box, IconButton, List, Text} from '@lguuxe/2024_designsystem_uni';
import React, {useState} from 'react';
import {PartnerDetailData} from "../../pages/Partner/types";
import {useNavigate} from "react-router-dom";
import {sendEventReturn} from "../../api/nativeBridge";
import CommonLocation from "./CommonLocation";

interface QuickLinkProps {
    partnerInfo: PartnerDetailData;
}

type SlotItemProps = {
    label: string;
    content?: string;
    onClick: () => void;
};

const QuickLink: React.FC<QuickLinkProps> = ({partnerInfo}) => {
    const navigate = useNavigate();
    const [locationAgree, setLocationAgree] = useState(false);

    const handleMap = () => {
        setLocationAgree(true);
    }
    const SlotItem: React.FC<SlotItemProps> = ({label, content, onClick}) => (
        <List
            type="view"
            fill="none"
            items={[
                {
                    onClick,
                    leftSlot: (
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <Text typo="font_body_2M" color="color_text_neutral_6">
                                {label}
                            </Text>
                        </div>
                    ),
                    rightSlot: (
                        <>
                            <IconButton
                                color="neutral"
                                fill="icon"
                                icon="chevron_right_small_line"
                                noStates
                            />
                            {content && (
                                <Text typo="font_label_2Sb" color="color_text_neutral_3">
                                    {content}
                                </Text>
                            )}
                        </>
                    ),
                    dataAttributes: {
                        'data-gtm-event-name': 'all_click',
                        'data-gtm-event-category': '클릭',
                        'data-gtm-event-action': `이용방법 안내 - 링크 클릭`,
                        'data-gtm-event-label': `컨텐츠 : ${label}`,
                        'data-gtm-click-url': '',
                        'data-gtm-click-location': `이용방법 안내`,
                        'data-gtm-click-direction': '내부',
                        'data-gtm-click-text': `${label}`,
                        'data-gtm-affiliate-id': partnerInfo.ptnId,
                        'data-gtm-affiliate-name': partnerInfo.ptnName,
                    }
                },

            ]}
        />
    );

    const items: SlotItemProps[] = [
        partnerInfo.homepage ? {
            label: "제휴사 홈페이지",
            onClick: () => sendEventReturn('EXTERNAL_CALL', {type: '1', url: partnerInfo.homepage})
        } : undefined,
        partnerInfo.youthYn === 'Y' ? {
            label: "유쓰 홈페이지",
            onClick: () => sendEventReturn('EXTERNAL_CALL', {type: '1', url: 'https://www.uth.co.kr/'})
        } : undefined,
        partnerInfo.longTermYn === 'Y' ? {
            label: "장기고객 혜택",
            onClick: () => {
                console.log("TODO: 장기고객 탭으로 이동");
                navigate('/benefit-guide/13');
            }
        } : undefined,
        partnerInfo.youthYn === 'Y' ? {
            label: "유쓰 혜택",
            onClick: () => {
                console.log("TODO: 유쓰 혜택 탭으로 이동");
                navigate('/benefit-guide');
            }
        } : undefined,
        partnerInfo.phone ? {
            label: "제휴사 대표전화",
            content: partnerInfo.phone,
            onClick: () => {
                window.location.href = `tel:+82${partnerInfo.phone}`
            }
        } : undefined,
        {
            label: "내 주변 매장 찾기",
            onClick: handleMap
        }
    ].filter((item): item is SlotItemProps => item !== undefined); // 조건에 따라 생성된 항목만 필터링

    return (
        <>
            <div>
                <Box style={{padding: "0 20px 48px"}} type="1_rbl">
                    <div style={{padding: "0", background: "#F3F5F6", borderRadius: "12px"}}>
                        {items.map((item, index) => (
                            <SlotItem
                                key={index}
                                label={item.label}
                                content={item.content}
                                onClick={item.onClick}
                            />
                        ))}
                    </div>
                </Box>
            </div>
            {/*TODO 의도한 기능 하는지 확인 필요 도영갓*/}

            {/*<Box style={{paddingBottom: '40px'}}>*/}
            {/*    <Box style={{background: '#F3F5F6', borderRadius: '12px', padding: '0'}}>*/}
            {/*        <List*/}
            {/*            fill='none'*/}
            {/*            items={[*/}
            {/*                ...(partnerInfo.homepage ? [{*/}
            {/*                    onClick: () => {*/}
            {/*                        sendEventReturn('EXTERNAL_CALL', {type: '1', url: partnerInfo.homepage});*/}
            {/*                    },*/}
            {/*                    text: {*/}
            {/*                        title: '제휴사 홈페이지',*/}
            {/*                    },*/}
            {/*                    linkIconOnlyId: 'placeholder',*/}
            {/*                }] : []),*/}
            {/*                ...(partnerInfo.youthYn === 'Y' ? [{*/}
            {/*                    onClick: () => {*/}
            {/*                        sendEventReturn('EXTERNAL_CALL', {type: '1', url: 'https://www.uth.co.kr/'});*/}
            {/*                    },*/}
            {/*                    text: {*/}
            {/*                        title: '유쓰 홈페이지',*/}
            {/*                    },*/}
            {/*                    linkIconOnlyId: 'placeholder',*/}
            {/*                }] : []),*/}
            {/*                ...(partnerInfo.longTermYn === 'Y' ? [{*/}
            {/*                    onClick: () => {*/}
            {/*                        console.log('TODO: 장기고객 탭으로 이동');*/}
            {/*                        navigate('/benefit-guide/13');*/}
            {/*                    },*/}
            {/*                    text: {*/}
            {/*                        title: '장기고객 혜택',*/}
            {/*                    },*/}
            {/*                    linkIconOnlyId: 'placeholder',*/}
            {/*                }] : []),*/}
            {/*                ...(partnerInfo.youthYn === 'Y' ? [{*/}
            {/*                    onClick: () => {*/}
            {/*                        console.log('TODO: 유쓰 혜택 탭으로 이동');*/}
            {/*                        navigate('/benefit-guide');*/}
            {/*                    },*/}
            {/*                    text: {*/}
            {/*                        title: '유쓰 혜택',*/}
            {/*                    },*/}
            {/*                    linkIconOnlyId: 'placeholder',*/}
            {/*                }] : []),*/}
            {/*                ...(partnerInfo.phone ? [{*/}
            {/*                    onClick: () => {*/}
            {/*                        window.location.href = `tel:+82${partnerInfo.phone}`;*/}
            {/*                    },*/}
            {/*                    text: {*/}
            {/*                        title: '제휴사 대표전화',*/}
            {/*                    },*/}
            {/*                    linkIconOnlyId: 'placeholder',*/}
            {/*                }] : []),*/}
            {/*                {*/}
            {/*                    onClick: () => {*/}
            {/*                        handleMap();*/}
            {/*                    },*/}
            {/*                    text: {*/}
            {/*                        title: '내 주변 매장 찾기',*/}
            {/*                    },*/}
            {/*                    linkIconOnlyId: 'placeholder',*/}
            {/*                },*/}
            {/*            ]}*/}
            {/*            type='view'*/}
            {/*            // style={{ marginBottom: 'none' }}*/}
            {/*        />*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            <CommonLocation isOpen={locationAgree} onClose={() => setLocationAgree(false)} ptnId={partnerInfo.ptnId}/>
        </>
    );
};

export default QuickLink;
