import React, {useEffect, useState} from 'react';
import {Box, Button, CTA, IconButton, Image, List, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import CommonAllIconHeader from '../../components/Common/CommonAllIconHeader';
import SortFilter from '../../components/Partner/SortFilter';
import {partnerApi} from '../../api/gatewayApi/partnerAndAffiliate-service';
import {PartnerItem} from '../Partner/types';
import {handlePromiseResult} from '../../api/api';
import useLoading from '../../hooks/useLoading';
import defaultThumbnail from '../../images/default/default thumbnail.svg';
import {useNavigate} from 'react-router-dom';
import CommonPopup from '../../components/Common/CommonPopup';
import emptyPartnerImg from '../../images/img/empty/empty_partner.svg';
import {useSnackbar} from "../../provider/SnackbarProvider";
import {gtmDeleteComp, gtmOpenPopup, gtmPageLoad} from "../../api/gtm";
import {formatLikeNum} from "../../utils/currencyUtils";
import { brazeEventApi } from '../../api/brazeBridge';

const sortList = [
    {
        id: '1',
        label: '최근 추가한 순',
    },
    {
        id: '2',
        label: '가나다순',
    },
];

const MyLikedPartners: React.FC = () => {
    const [likedPartnerList, setLikedPartnerList] = useState<PartnerItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showRemovePartnerPopup, setShowRemovePartnerPopup] = useState<boolean>(false);
    const [selectedPartnerId, setSelectedPartnerId] = useState('');
    const navigate = useNavigate();
    const {showSnackbar} = useSnackbar();

    useEffect(() => {
        fetchLikedPartners('');
        const func = async () => {
            await gtmPageLoad({
                url: '/my/partners',
                contentGroup: '혜택|제휴사|즐겨찾기 제휴사',
                moduleName: '',
                pageTitle: '좋아하는 제휴사'
            });
        }
        func()
    }, []);

    const fetchLikedPartners = async (sort: string) => {
        try {
            const params = {
                orderType: sort, // 빈값: 최신, A: 가나다
            };
            const [partners] = await Promise.allSettled([partnerApi.getPartnersLike(params, 'v1')]);
            setLikedPartnerList(handlePromiseResult(partners, {ptnList: []}).ptnList);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLike = async () => {
        try {
            const requestParam = {
                regYn: 'N',
            };
            partnerApi.putPartnersLikeById(selectedPartnerId, requestParam, 'v1').then(res => {
                if (res.header.resultCode === '0000') {
                    const selectedPartner = likedPartnerList.find(partner => partner.ptnId === selectedPartnerId);
                    setLikedPartnerList(likedPartnerList.filter(partner => partner.ptnId !== selectedPartnerId));
                    console.log('좋아하는 제휴사에서 제외했어요.');
                    gtmDeleteComp('제휴사 즐겨찾기 삭제', selectedPartner ? selectedPartner.ptnName : '');

                    if(selectedPartner) {
                        brazeEventApi.my_preferred_affiliate_cancel(selectedPartner.ptnName, selectedPartner.ptnId,  selectedPartner.ptnCateName || "");
                    }
                }
                showSnackbar('좋아하는 제휴사에서 제외했어요.');
            });
        } catch (error) {
            console.error('Failed to fetch :', error);
        }
    };

    const handleSortChange = (sortTypeId: string) => {
        const sort = sortTypeId === '1' ? '' : 'A';
        fetchLikedPartners(sort);
    };

    const handleLikeClick = (partner: PartnerItem) => {
        setShowRemovePartnerPopup(true);
        gtmOpenPopup('즐겨찾기 제휴사 삭제 안내 팝업');
        setSelectedPartnerId(partner.ptnId);
    };

    const handleRemovePartner = () => {
        fetchLike();
        setShowRemovePartnerPopup(false);

    };

    const handlePartnerClick = (partner: PartnerItem) => {
        navigate(`/partners/${partner.ptnId}`);
    }

    const renderNoPartner = () => (
        <>
            <SortFilter
                totalCount={likedPartnerList.length.toString()}
                sortList={sortList}
                onSortChange={handleSortChange}
                isUsableCheckBox={false}
            />
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '88px 0',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Image src={emptyPartnerImg} alt='placeholder' width='120px' ratio='1:1'/>
                    <div style={{paddingTop: '20px'}}>
                        <Text typo='font_detail_1M' color='color_text_neutral_3' textAlign='center'>
                            좋아하는 제휴사가 없어요.
                            <br/>
                            관심있는 제휴사에 하트를 눌러 보세요.
                        </Text>
                    </div>
                </div>
            </Box>
        </>
    );

    return (
        <>
            {useLoading(isLoading)}
            <CommonAllIconHeader title={'좋아하는 제휴사'} isSearch={false}/>

            {likedPartnerList.length === 0 && renderNoPartner()}
            {likedPartnerList.length > 0 && (
                <div style={{paddingBottom: '100px'}}>
                    <SortFilter
                        totalCount={likedPartnerList.length.toString()}
                        sortList={sortList}
                        onSortChange={handleSortChange}
                        isUsableCheckBox={false}
                    />
                    <List
                        type='view'
                        fill='none'
                        id='partnerList'
                        isSlotClickable={true}
                        items={likedPartnerList.map(partner => ({
                            leftSlot: [
                                <Image
                                    key={`image-${partner.ptnId}`}
                                    src={partner.ptnLogoImgUrl}
                                    ratio='1:1'
                                    width='48px'
                                    rounded={'50%'}
                                    srcFallback={defaultThumbnail}
                                    onClick={() => handlePartnerClick(partner)}
                                    data-gtm-event-name={'all_click'}
                                    data-gtm-event-category={'클릭'}
                                    data-gtm-event-action={'혜택|제휴사|즐겨찾기 제휴사 - 링크 클릭'}
                                    data-gtm-event-label={`컨텐츠 : ${partner.ptnName}`}
                                    data-gtm-click-url={''}
                                    data-gtm-click-location={'혜택|제휴사|즐겨찾기 제휴사'}
                                    data-gtm-click-direction={'내부'}
                                    data-gtm-click-text={`${partner.ptnName}`}
                                    data-gtm-affiliate-id={partner.ptnId}
                                    data-gtm-affiliate-name={partner.ptnName}
                                />,
                                <div
                                    key={`info-${partner.ptnId}`}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.5rem',
                                    }}
                                    onClick={() => handlePartnerClick(partner)}
                                    data-gtm-event-name={'all_click'}
                                    data-gtm-event-category={'클릭'}
                                    data-gtm-event-action={'혜택|제휴사|즐겨찾기 제휴사 - 링크 클릭'}
                                    data-gtm-event-label={`컨텐츠 : ${partner.ptnName}`}
                                    data-gtm-click-url={''}
                                    data-gtm-click-location={'혜택|제휴사|즐겨찾기 제휴사'}
                                    data-gtm-click-direction={'내부'}
                                    data-gtm-click-text={partner.ptnName}
                                    data-gtm-affiliate-id={partner.ptnId}
                                    data-gtm-affiliate-name={partner.ptnName}
                                >
                                    <TextSet
                                        subComponent='Body_1'
                                        text={{
                                            eyebrow: (
                                                <>
                                                    <span>{partner.benefitSummary}</span>
                                                </>
                                            ),
                                            title: partner.ptnName,
                                        }}
                                    />
                                </div>,
                            ],
                            rightSlot: [
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <IconButton
                                        key={`like-${partner.ptnId}`}
                                        icon='favorite_line'
                                        size='large'
                                        fill='icon'
                                        state='default'
                                        isSelected
                                        selectable={false}
                                        onClick={() => handleLikeClick(partner)}
                                    />
                                    <TextSet subComponent={'Body_2'} text={{
                                        title: formatLikeNum(partner.likeNum),
                                    }}></TextSet>
                                </div>


                            ],
                        }))}
                    />
                </div>
            )}

            <div
                style={{
                    position: 'fixed',
                    bottom: '0',
                    width: '100%',
                }}
            >
                <CTA layout='column' style={{paddingBottom: '32px'}}>
                    <Button color='primary' size='large' rightIcon='add_line' onClick={() => navigate(`/partners`)}
                            data-gtm-event-name={'all_click'}
                            data-gtm-event-category={'클릭'}
                            data-gtm-event-action={'혜택|제휴사|즐겨찾기 제휴사 - 링크 클릭'}
                            data-gtm-event-label={`컨텐츠 : 좋아하는 제휴처 추가`}
                            data-gtm-click-url={''}
                            data-gtm-click-location={'혜택|제휴사|즐겨찾기 제휴사'}
                            data-gtm-click-direction={'내부'}
                            data-gtm-click-text={'좋아하는 제휴처 추가'}
                    >
                        좋아하는 제휴처 추가
                    </Button>
                </CTA>
            </div>

            <CommonPopup
                description={'좋아하는 제휴사에서 삭제하시겠어요?'}
                submitText={'삭제'}
                onSubmit={handleRemovePartner}
                cancelText={'취소'}
                onClose={() => {
                    setShowRemovePartnerPopup(false);
                }}
                isOpen={showRemovePartnerPopup}
            />
        </>
    );
};

export default MyLikedPartners;