import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {BottomNavigation, Image, Text} from '@lguuxe/2024_designsystem_uni';
import {useNavigate} from 'react-router-dom';
import BS_Barcode from '../Barcode/BS_Barcode';
import {getItemByKey} from "../../utils/indexedDB";
import CommonPopup from "./CommonPopup";
import {brazeEventApi} from "../../api/brazeBridge";
import CommonLoginPopup from "./CommonLoginPopup";
import homeIn from '../../images/json/GNB/In_홈.json'
import missionIn from '../../images/json/GNB/In_미션.json'
import myIn from '../../images/json/GNB/In_마이.json'
import entireIn from '../../images/json/GNB/In_전체.json'
import barcodeIn from '../../images/json/GNB/In_닫기.json'
import barcodeOut from '../../images/json/GNB/Out_닫기.json'
import home_line from '../../images/main/benefit/home_line.svg'
import menu_line from '../../images/main/benefit/menu_line.svg'
import mission_line from '../../images/main/benefit/mission_line.svg'
import my_line from '../../images/main/benefit/my_line.svg'
import barcodeInImage from '../../images/main/benefit/barcode_line.svg'
import Lottie from "lottie-react";

export interface NavigationBarHandles {
    triggerKeypressBack: () => void;
}

interface NavigationBarProps {
    barcodeClose?: () => void;
    selectedItem?: 'home' | 'mission' | 'my' | 'entire';
    onBackKeyPress?: (haveToAction: boolean) => void;
    barcodeOpen?: boolean;
}

const NavigationBar = forwardRef<NavigationBarHandles, NavigationBarProps>(({
                                                                                barcodeClose,
                                                                                selectedItem = 'home',
                                                                                onBackKeyPress,
                                                                                barcodeOpen
                                                                            }, ref) => {
    const [isBarcodeOpen, setIsBarcodeOpen] = useState(false);
    const [barcodeLottieActive, setBarcodeLottieActive] = useState(false)
    const [isUnder14PopupOpen, setIsUnder14PopupOpen] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [noCardInfo, setNoCardInfo] = useState(false);
    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
        triggerKeypressBack() {
            if (isBarcodeOpen) {
                handleBarcodeToggle(false);
            } else {
                if (onBackKeyPress) {
                    onBackKeyPress(true);
                } else {
                    navigate(-1);
                }
            }
        }
    }));

    useEffect(() => {
        const handleKeypressBack = (event: CustomEvent) => {
            handleBarcodeToggle(false);
        }
        window.addEventListener('keypressBack', handleKeypressBack as EventListener);
        return () => {
            window.removeEventListener('keypressBack', handleKeypressBack as EventListener);
        }
    }, []);

    useEffect(() => {
        // 홈화면 진입시 바코드 노출
        if (barcodeOpen) {
            const fetchInfo = async () => {
                const memberStatus = await getItemByKey('memberStatus');
                const memberId = await getItemByKey('memberId');
                // 자사, 로그인 사용자만 바코드 팝업 노출
                if (memberId && memberStatus && !['5', '0'].includes(memberStatus)) {
                    setBarcodeLottieActive(true)
                    setNoCardInfo(true);
                    setIsBarcodeOpen(true);
                }
            }
            fetchInfo()
        }
    }, [barcodeOpen])

    const handleIconClick = async (id: string) => {
        const memberStatus = await getItemByKey('memberStatus');
        const memberId = await getItemByKey('memberId');

        switch (id) {
            case 'home':
                window.scrollTo(0, 0);
                navigate('/');
                setIsBarcodeOpen(false);
                break;
            case 'mission':
                if (!memberId) { //미로그인시 본인인증 팝업
                    setShowLoginPopup(true);
                    break;
                }
                if (memberStatus === '3' || memberStatus === '4') { //14세 미만 접근시 접근 불가 팝업
                    setIsUnder14PopupOpen(true);
                    break;
                }
                window.scrollTo(0, 0);
                navigate('/mission');
                setIsBarcodeOpen(false);
                break;
            case 'my':
                if (!memberId) { //미로그인시 본인인증 팝업
                    setShowLoginPopup(true);
                    break;
                }
                window.scrollTo(0, 0);
                navigate('/my');
                setIsBarcodeOpen(false);
                break;
            case 'entire':
                brazeEventApi.total_menu_click();
                window.scrollTo(0, 0);
                navigate('/entire');
                setIsBarcodeOpen(false);
                break;
            default:
                break;
        }
    };
    const handleBarcodeClick = () => {
        setBarcodeLottieActive(true)

        if (barcodeClose) {
            brazeEventApi.home_barcode_floating_close()
            barcodeClose();
        } else {
            if (isBarcodeOpen) brazeEventApi.home_barcode_floating_close()
            else brazeEventApi.home_barcode_floating_click()
            setIsBarcodeOpen(!isBarcodeOpen);
        }
    }

    const handleBarcodeToggle = (isOpen: boolean) => {
        if (isBarcodeOpen && !isOpen) {
            brazeEventApi.home_barcode_floating_close();
        }
        setIsBarcodeOpen(isOpen);
    };

    return (
        <>
            <BS_Barcode
                isOpen={isBarcodeOpen}
                onToggle={handleBarcodeToggle}
                noCardInfo={noCardInfo}/>
            <div
                style={{
                    position: 'fixed',
                    bottom: '0',
                    width: '100%',
                    zIndex: isBarcodeOpen ? 1000 : 999,
                    // 디자인 시스템 popup 과 bottom sheet 는 z-index 가 999 임.
                }}
            >
                <BottomNavigation
                    items={[
                        {
                            id: 'home',
                            content: <div style={{
                                display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1px'
                            }}>
                                {selectedItem === 'home' ?
                                    <Lottie loop={false} animationData={homeIn}/> :
                                    // <Lottie loop={false} animationData={homeOut}/>}
                                    <>
                                        {/*<Icon name='home_line' size="24px" style={{padding: '0'}}/>*/}
                                        <Image src={home_line} width="24px" ratio="1:1"/>
                                        <Text typo='font_label_3Sb' color='color_text_neutral_3'>
                                            홈
                                        </Text>
                                    </>}
                            </div>,
                            dataAttributes: {
                                'data-gtm-event-name': 'all_click',
                                'data-gtm-event-category': '클릭',
                                'data-gtm-event-action': 'GNB - 링크 클릭',
                                'data-gtm-event-label': '컨텐츠 : 홈',
                                'data-gtm-click-url': '',
                                'data-gtm-click-location': 'GNB',
                                'data-gtm-click-direction': '내부',
                                'data-gtm-click-text': '홈'
                            }
                        },
                        {
                            id: 'mission',
                            content:
                                <div
                                    style={{
                                        display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1px'
                                    }}>
                                    {selectedItem === 'mission' ?
                                        <Lottie loop={false} animationData={missionIn}/> :
                                        <>
                                            {/*<Icon name='flag_line' size="24px" style={{padding: '0'}}/>*/}
                                            <Image src={mission_line} width="24px" ratio="1:1"/>
                                            <Text typo='font_label_3Sb' color='color_text_neutral_3'>
                                                미션
                                            </Text>
                                        </>}
                                </div>,
                            dataAttributes: {
                                'data-gtm-event-name': 'all_click',
                                'data-gtm-event-category': '클릭',
                                'data-gtm-event-action': 'GNB - 링크 클릭',
                                'data-gtm-event-label': '컨텐츠 : 미션',
                                'data-gtm-click-url': '',
                                'data-gtm-click-location': 'GNB',
                                'data-gtm-click-direction': '내부',
                                'data-gtm-click-text': '미션'
                            }
                        },
                        {
                            id: 'my',
                            content:
                                <div
                                    style={{
                                        display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1px'
                                    }}>
                                    {selectedItem === 'my' ?
                                        <Lottie loop={false} animationData={myIn}/> :
                                        <>
                                            {/*<Icon name='person_line' size="24px"/>*/}
                                            <Image src={my_line} width="24px" ratio="1:1"/>
                                            <Text typo='font_label_3Sb' color='color_text_neutral_3'>
                                                마이
                                            </Text>
                                        </>}
                                </div>,
                            dataAttributes: {
                                'data-gtm-event-name': 'all_click',
                                'data-gtm-event-category': '클릭',
                                'data-gtm-event-action': 'GNB - 링크 클릭',
                                'data-gtm-event-label': '컨텐츠 : 마이',
                                'data-gtm-click-url': '',
                                'data-gtm-click-location': 'GNB',
                                'data-gtm-click-direction': '내부',
                                'data-gtm-click-text': '마이'
                            }
                        },
                        {
                            id: 'entire',
                            content:
                                <div
                                    style={{
                                        display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1px'
                                    }}>
                                    {selectedItem === 'entire' ?
                                        <Lottie loop={false} animationData={entireIn}/> :
                                        <>
                                            {/*<Icon name='menu_line' size="24px"/>*/}
                                            <Image src={menu_line} width="24px" ratio="1:1"/>
                                            <Text typo='font_label_3Sb' color='color_text_neutral_3'>
                                                전체
                                            </Text>
                                        </>}

                                </div>,
                            dataAttributes: {
                                'data-gtm-event-name': 'all_click',
                                'data-gtm-event-category': '클릭',
                                'data-gtm-event-action': 'GNB - 링크 클릭',
                                'data-gtm-event-label': '컨텐츠 : 전체',
                                'data-gtm-click-url': '',
                                'data-gtm-click-location': 'GNB',
                                'data-gtm-click-direction': '내부',
                                'data-gtm-click-text': '전체'
                            }
                        },
                    ]}
                    selectedItemId={selectedItem}
                    onItemSelected={handleIconClick}
                >
                    <BottomNavigation.centerSlot>
                        <div
                            style={{
                                alignItems: 'center',
                                bottom: '1rem',
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'relative',
                                width: '100%',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '2px',
                                    width: '56px',
                                    height: '56px',
                                    borderRadius: '50%',
                                    border: '1px solid #F3F5F6',
                                    background: !isBarcodeOpen ? '#FFF' : '#000', // 조건에 따라 배경색 설정
                                }}
                                onClick={handleBarcodeClick}
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={'GNB - 링크 클릭'}
                                data-gtm-event-label={'컨텐츠 : 바코드'}
                                data-gtm-click-url={''}
                                data-gtm-click-location={'GNB'}
                                data-gtm-click-direction={'내부'}
                                data-gtm-click-text={'바코드'}
                            >
                                {barcodeLottieActive ? (
                                        !isBarcodeOpen ?
                                            <Lottie animationData={barcodeOut} loop={false}/> :
                                            <Lottie animationData={barcodeIn} loop={false}/>
                                    ) :
                                    <>
                                        <Image src={barcodeInImage} width="24px" ratio="1:1"/>
                                        <Text typo='font_label_3Sb' color='color_text_neutral_3'>
                                            바코드
                                        </Text>
                                    </>
                                }
                            </div>
                        </div>
                    </BottomNavigation.centerSlot>
                </BottomNavigation>
            </div>

            <CommonLoginPopup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}/>

            <CommonPopup isOpen={isUnder14PopupOpen} submitText='확인' description='만 14세 이상 고객이 이용할 수 있어요.'
                         onSubmit={() => {
                             setIsUnder14PopupOpen(false)
                         }}/>
        </>
    );
});

export default NavigationBar;
