import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import HomeBenefit from './HomeBenefit';
import commonApi from '../../api/gatewayApi/common';
import {defaultMembershipInfo, MembershipInfo, session} from './types';
import CommonPopup from "../../components/Common/CommonPopup";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import {sendEvent, sendEventReturn} from "../../api/nativeBridge";
import useLoading from "../../hooks/useLoading";
import {ApiResponse} from "../../api/types";
import {callCheckPassword, getAgeCode, getMemberStatus, goAppStore} from "../../utils/helper";
import MembershipsService from "../../api/gatewayApi/memberships-service";
import {useNavigate, useSearchParams} from "react-router-dom";
import CommonPopupWithImage from "../../components/Common/CommonImagePopup";
import paymentApi from "../../api/gatewayApi/payment";
import {useFullScreen} from "../../provider/FullScreenContext";
import AdditionalAgreements from "../Agreements/AdditionalAgreements";
import BarcodePopup from "../../components/Common/BarcodePopup";
import {gtmUserInfo} from "../../api/gtm";
import Tutorial from "../Tutorial/Tutorial";
import {Agreement} from "../Agreements/types";
import {useMessagePopup} from "../../provider/MessagePopupProvider";
import useLandingPage from "../../hooks/useLandingPage";
import {adSSP} from "../../api/externalApi";
import CommonLoginPopup from "../../components/Common/CommonLoginPopup";
import {brazeAttributeApi, brazeEventApi} from "../../api/brazeBridge";
import NavigationBar from "../../components/Common/NavigationBar";
import {decrypt, generateMCP_TR_KEY} from "../../api/authFunctions";

export interface HomeBenefitPopupHandles {
    // triggerShowAddPopup: () => void;
    triggerFetchSession: (init?: boolean) => void;
    waitingPopupSuccess: (id: string) => void;
    triggerSetVipYn: () => void;
    stopPayExistPopup: () => void;
}

interface Popup {
    popupList?: Array<{ imageUrl: string; url: string; [key: string]: any }>;
    type: string;
    onSubmit: () => void;
    submitText: string;
    onClose?: () => void;
    title?: string;
    imageUrl?: string;
    description?: string;
    cancelText?: string;
    showCheckbox?: boolean;
    onCheckbox?: () => void;
    gtmType?: string;
}

interface HomeBenefitPopupProps {
    navigationBarRef: React.Ref<any>;
}

const HomeBenefitPopup = forwardRef<HomeBenefitPopupHandles, HomeBenefitPopupProps>(({navigationBarRef}, ref) => {
    const navigate = useNavigate();
    const [openBarcodePopup, setOpenBarcodePopup] = useState(false)
    const [popupContent, setPopupContent] = useState<Popup | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showAdPopup, setShowAdPopup] = useState(false); // 팝업 표시 여부를 관리하는 상태
    const [ssplogoUrl, setSsplogoUrl] = useState("");
    const [logoLandingUrl, setLogoLandingUrl] = useState("");
    const [userStatus, setUserStatus] = useState<string>('0');
    const [popupQueue, setPopupQueue] = useState<Popup[]>([]);
    const [landingUrl, setLandingUrl] = useState<string[]>([]);// 클릭 시 이동할 랜딩 URL
    const [sspLandingUrl, setSspLandingUrl] = useState<string>('');
    const [popupImageUrl, setPopupImageUrl] = useState<string | null>(null);
    const [clickUrls, setClickUrls] = useState<string[]>([]); // 클릭 시 호출할 URL 리스트
    const [useVipYn, setUseVipYn] = useState<boolean>(true)
    const [openErrorPopup, setOpenErrorPopup] = useState(false);
    const {showMessagePopup} = useMessagePopup()
    const [searchParams] = useSearchParams();
    const {show} = useFullScreen();
    const [showTutorial, setShowTutorial] = useState<boolean>(false);
    const {landingPage} = useLandingPage();
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [navigateUpdate, setNavigateUpdate] = useState(false);
    const [openBarcode, setOpenBarcode] = useState(false);
    const [stopPayExistPopup, setStopPayExistPopup] = useState(false);

    useImperativeHandle(ref, () => ({
        // async triggerShowAddPopup() {
        //     //튜토리얼이 떠있는 메인에서 AOS back키 클릭시엔 튜토리얼을 닫도록 동작합니다.
        //     if (showTutorial) {
        //         await handleCloseTutorial()
        //     } else {
        //         await showPopup()
        //     }
        // },
        async triggerFetchSession(init) {
            await fetchSession(init)
        },
        waitingPopupSuccess(id) {
            console.log('waitingPopupSuccess  id : ', id)
            navigate(`/uptp-detail/${id}`);
        },
        async triggerSetVipYn() {
            await setUseVIPYn();
        },
        stopPayExistPopup() {
            setStopPayExistPopup(true)
        }
    }));

    const finishApp = () => {
        sendEvent('APP_FINISH', {})
    }

    useEffect(() => {
        const checkFirst = async () => {
            if (await getItemByKey('sessionKey')) {
                setIsLoading(false)
            } else {
                // setIsLoading(true)
            }

            const isfirstLoading = await getItemByKey('first_loading')
            if (isfirstLoading || isfirstLoading === undefined) {
                setShowTutorial(true)
            } else {
                setShowTutorial(false)
            }
        }

        const getUserStatus = async () => {
            const userStatus = await getItemByKey("memberStatus")
            setUserStatus(userStatus)
        }
        getUserStatus()
        const requestSession = async () => {
            await fetchSession(true);
        }
        const requestVipkokYn = async () => {
            //초기에는
            const firstLoading = await getItemByKey('first_loading');
            if (firstLoading === false) {
                const isVIP = await getItemByKey('isVIP');
                const memberId = await getItemByKey('memberId')
                if (isVIP === "Y" && memberId) {
                    await setUseVIPYn();
                } else {
                    setIsLoading(false);
                }
            }
        }

        if (process.env.REACT_APP_ENV_MODE === 'local') {
            //local에선 INITIAL_LOADING_FINISHED을 트리거로 받을수 없으니 추가
            requestSession()
        }

        //join하고 넘어왔을때 한번 호출
        if (searchParams.get('state') === 'first') {
            requestSession()
            requestVipkokYn()
        } else if (searchParams.get('state') === 'barcode') {
            setOpenBarcode(true)
        } else {
            //탭간 이동시에도 VIPkok 사용 여부는 체크해야 하므로 추가
            requestVipkokYn()
        }

        checkFirst()
        // TODO: remove
        // setShowAdPopup(true);
    }, [])

    const handlePopupSubmit = () => {
        // 팝업 확인 버튼 클릭 시 수행할 동작
        setShowAdPopup(false);
        sendEvent("APP_FINISH", {})
        console.log("Popup submit action performed");
    };

    const handleImageClick = () => {
        if (userStatus === "0") {
            setShowLoginPopup(true);
            return;
        }

        // 외부 브라우저로 이동
        if (landingUrl) {
            // brazeEventApi.mkt_popup_open("","");
            landingPage(landingUrl[0], landingUrl[1], navigate, landingUrl[2]);
            setTimeout(() => {
                closePopup();
            }, 1000);
        }
    };

    const handleImageCloseClick = () => {
        // brazeEventApi.mkt_popup_close("","");
        closePopup();
    };

    const handleEndImageClick = () => {
        if (userStatus === "0") {
            setShowLoginPopup(true);
            return;
        }

        clickUrls.forEach(url => {
            fetch(url)
                .then(response => console.log(`Click URL called: ${url}`, response))
                .catch(error => console.error(`Error calling click URL: ${url}`, error));
        });

        if (sspLandingUrl) {
            sendEventReturn('EXTERNAL_CALL', {type: '1', url: sspLandingUrl});
        }
    }

    const showPopup = async () => {
        console.log('No more history, perform specific action');
        let adResponse
        try { // SSP API 호출
            setShowAdPopup(true);
            adResponse = await adSSP('비식별');
            // 받은 데이터에서 이미지 URL을 추출하고 impression_urls 호출
            if (adResponse.exist && adResponse.creatives.length > 0) {
                const firstCreative = adResponse.creatives[0]; // 첫 번째 크리에이티브를 사용

                // impression_urls 호출
                firstCreative.impression_urls?.forEach((url: URL | RequestInfo) => {
                    fetch(url)
                        .then(response => console.log(`Impression URL called: ${url}`, response))
                        .catch(error => console.error(`Error calling impression URL: ${url}`, error));
                });

                setPopupImageUrl(firstCreative.image_url || null); // 이미지 URL 설정
                setClickUrls(firstCreative.click_urls || []); // 클릭 시 호출할 URL 설정
                setSspLandingUrl(firstCreative.landing_url || ''); // SSP 랜딩 URL 설정
                setSsplogoUrl(firstCreative.logo_image_url || null); // 로고 이미지 설정
                setLogoLandingUrl(firstCreative.logo_landing_url || null); // 로고 이미지 랜딩 경로
            } else {
                setShowAdPopup(true);
                setPopupImageUrl(null);
            }
        } catch (e) {
            console.log(e)
            setPopupImageUrl(null);
        }
    }


    const fetchSession = async (init?: boolean) => {
        try {
            const mcpTrKey = generateMCP_TR_KEY()
            const response: ApiResponse<session> = await commonApi.getSession({}, 'v1', mcpTrKey);
            const data: session = response.body;
            if (response.header.resultCode !== '0000' || !data) {
                setIsLoading(false)
                setOpenErrorPopup(true)
            } else {
                await addSessionData(data, mcpTrKey);
                setIsLoading(false)
                let memberStatus = '0';
                let cardInfo: MembershipInfo = defaultMembershipInfo
                if (data.memberId) {
                    if (['45', '46', '47'].includes(data.memberStatus)) {
                        await addItem('memberStatus', "5")
                        memberStatus = "5"
                        setUserStatus("5")
                    } else {
                        try {
                            const response2: ApiResponse<MembershipInfo> = await MembershipsService.getCardInfo({}, 'v1');
                            cardInfo = response2.body;
                            sendEvent("SET_UPM_BARCODE", {barcode: cardInfo.barcode})
                        } catch {
                            console.log("cardInfo error")
                        }
                        memberStatus = getMemberStatus(data.memberId, data.memberStatus, cardInfo.cardLevel)
                        await addItem('memberStatus', memberStatus)
                        setUserStatus(memberStatus)
                    }

                    // 특정 값이 변한 경우 braze attributeapi 호출
                    const cdLv = await getItemByKey('cardLevel')
                    const moneyMeUse = await getItemByKey('moneyMeUse')
                    const longTermName = await getItemByKey('longTermName');
                    if(moneyMeUse && moneyMeUse !== data.moneyMeUse) brazeAttributeApi.firstCollection();
                    if(cdLv && cdLv !== cardInfo.cardLevel) brazeAttributeApi.firstCollection();
                    if(longTermName && longTermName !== cardInfo.longTermName) brazeAttributeApi.firstCollection();

                    await addItem('cardLevel', cardInfo.cardLevel);
                    await addItem('token', cardInfo.token);
                    await addItem('encKey', cardInfo.encKey);
                    await addItem('youthYn', cardInfo.youthYn);
                    await addItem('longTermName', cardInfo.longTermName);
                    await addItem('longTermCode', cardInfo.longTermCode);
                    await addItem('isVIP', cardInfo.isVIP);
                    await addItem('totalDiscount', cardInfo.totalDiscount);
                    await addItem('barcode', cardInfo.barcode);

                    // login_complete는 앱다운 후 첫 로그인 시에만
                    if (searchParams.get('state') === 'first') {
                        console.log('sending brazeEventApi.logIn_complete . . .');
                        brazeEventApi.logIn_complete();
                    }

                    // if (cardInfo.isVIP === "Y") {
                    //     await setUseVIPYn()
                    // }
                } else { // 비로그인
                    await addItem('memberStatus', "0")
                    setUserStatus("0")
                }
                if (init) {
                    // removeItemByKey('barcode')
                    // setOpenBarcode(true);
                    await handlePopups(data, memberStatus);
                }
            }
        } catch (error) {
            console.error('Error fetching session detail:', error);
            setIsLoading(false)
            setOpenErrorPopup(true)
        } finally {
            await gtmUserInfo()
            // await gtmLogin()
            setIsLoading(false)
        }
    };

    const addSessionData = async (data: any, mcpTrKey: string) => {
        const sessionData = {
            subNo: data.subNo === null ? '' : data.subNo,
            subCustNo: data.subCustNo === null ? '' : data.subCustNo,
            customerName: data.customerName === null ? '' : data.customerName,
            serviceYackReqStatus: data.serviceYackReqStatus === null ? '' : data.serviceYackReqStatus,
            serviceNotiYn: data.serviceNotiYn === null ? '' : data.serviceNotiYn,
            maketingInfoYn: data.maketingInfoYn === null ? '' : data.maketingInfoYn,
            sessionKey: data.sessionKey === null ? '' : data.sessionKey,
            userStatus: data.userStatus === null ? '' : data.userStatus,
            payExistYn: data.payExistYn === null ? '' : data.payExistYn,
            lossPhone: data.lossPhone === null ? '' : data.lossPhone,
            emulUse: data.emulUse === null ? '' : data.emulUse,
            moneyMeUse: data.moneyMeUse === null ? '' : data.moneyMeUse,
            ci: data.ci === null ? '' : await decrypt(mcpTrKey, data.ci),
            dataDogSessionSampleRate: data.dataDogSessionSampleRate === null ? '' : data.dataDogSessionSampleRate,
            ...(data.userStatus !== null && {userStatus: data.userStatus}),
            ...(data.memberId !== null && {memberId: data.memberId}),
            ...(data.gender !== null && {gender: data.sex}),
            ...(data.birthday !== null && {birthday: data.birthday}),
            ...(data.telcoCode !== null && {telcoCode: data.telcoCode}), // telcode가 비어있으면 업데이트 안함
            ...(data.encMemberId !== null && {encMemberId: data.encMemberId}),
        }
        for (const [key, value] of Object.entries(sessionData)) {
            if (value !== undefined) {
                await addItem(key, value);
            }
        }
        await sendEvent('SET_AUTHORIZATION', {
            authorization: `${data.sessionKey}`
        })
        await sendEvent('SET_MEMBER_ID', {memberId: sessionData.memberId})
        await sendEvent('SET_USER_BIRTH', {userBirth: sessionData.birthday})
        await sendEvent('SET_USER_NAME', {userName: sessionData.customerName})
        await sendEvent("SET_MEMBERSTATUS", {memberStatus: data.memberStatus})
        await sendEvent("SET_ENC_MEMBER_ID", {encMemberId: data.encMemberId})
    };

    const setUseVIPYn = async () => {
        try {
            const response2 = await MembershipsService.getVipBenefitThisMonth('v1');
            if (response2.body.useVipkok && response2.body.useVipkok.length > 0) {
                await addItem('useVipkokYn', response2.body.useVipkok[0].isUsed);

                const vk = await getItemByKey('vipkok');

                await addItem('vipkok', {
                    ptnId: response2.body.useVipkok[0].ptnId,
                    ptnName: response2.body.useVipkok[0].ptnName,
                    isUsed: response2.body.useVipkok[0].isUsed
                })
                // braze attribute update
                if(vk?.ptnId !== response2.body.useVipkok[0].ptnId) brazeAttributeApi.firstCollection();
                if (response2.body.useVipkok[0].isUsed === 'Y') {
                    setUseVipYn(true)
                } else {
                    setUseVipYn(false)
                }

            } else {
                await addItem('useVipkokYn', 'N');
                setUseVipYn(false)
            }
        } catch (error) {
            console.log('Failed to fetch useVipkokYn :', error);
        } finally {
            setIsLoading(false)
        }
    }
    const handlePopups = async (data: session, memberStatus: string) => {
        const refuseMarketing = await getItemByKey("refuseMarketing")
        const refuseMinorUpdate = await getItemByKey("refuseMinorUpdate")
        const queue = [];
        if (data.lossPhone) {
            if (data.lossPhone === "Y") {
                queue.push({
                    type: 'common',
                    description: '휴대폰 분실 접수 상태로\n앱을 실행할 수 없습니다.\n분실 해제 후 이용해주세요.',
                    submitText: '앱 종료',
                    onSubmit: () => finishApp()
                });
            }
        }
        if (data.updateStatus) {
            if (data.updateStatus === '115001') {
                queue.push({
                    type: 'common',
                    title: '필수 업데이트 안내',
                    description: 'U+멤버십 앱을 계속 이용하려면 \n업데이트를 해야 해요.\n지금 업데이트를 하시겠어요?',
                    submitText: '업데이트',
                    onSubmit: () => goAppStore(),
                    cancelText: '앱 종료',
                    onClose: () => finishApp()
                });
            } else if (data.updateStatus === '115002') {
                if (userStatus !== "0" && userStatus !== "5") {
                    queue.push({
                        type: 'barcode',
                        title: "필수 업데이트 안내",
                        onClose: () => finishApp(),
                        onSubmit: () => goAppStore(),
                        description: "U+멤버십 앱을 계속 이용하려면\n업데이트를 해야 해요.\n지금 업데이트 하시겠어요?",
                        submitText: '업데이트',
                        cancelText: '앱 종료'
                    })
                } else {
                    queue.push({
                        type: 'common',
                        title: '필수 업데이트 안내',
                        description: 'U+멤버십 앱을 계속 이용하려면 \n업데이트를 해야 해요.\n지금 업데이트를 하시겠어요?',
                        submitText: '업데이트',
                        onSubmit: () => goAppStore(),
                        cancelText: '앱 종료',
                        onClose: () => finishApp()
                    });
                }
            } else if (data.updateStatus === '115003') {
                if (!refuseMinorUpdate) {
                    queue.push({
                        type: 'common',
                        title: '업데이트 안내',
                        description: 'U+멤버십 앱을 업데이트 할 수 있어요.\n업데이트하고 더 편리하게 이용해 보세요.',
                        submitText: '업데이트',
                        onSubmit: () => goAppStore(),
                        cancelText: '나중에',
                        onClose: async () => {
                            closePopup()
                            await checkTermData(data.serviceYackReqStatus)
                        },
                        showCheckbox: true,
                        onCheckbox: async () => {
                            await addItem("refuseMinorUpdate", true)
                        },
                    });
                }
            }
        } else {
            await checkTermData(data.serviceYackReqStatus)
        }

        if (data.serviceNotiYn) {
            let response
            try {
                response = await commonApi.getServiceNoti({}, 'v1')
                //TODO 뭐먼저 보여줄까
                const data = response.body.svcPopList[0]
                queue.push({
                    type: 'common',
                    title: data.svcTitle,
                    description: data.svcContent,
                    submitText: "확인",
                    onSubmit: () => closePopup()
                })
                // setLandingUrl([data.linkType, data.linkUrl]);
            } catch (e) {
                console.log(e)
            }
        }
        const currentDay = await getItemByKey("currentDay")
        // 마케팅 팝업을 다시보지 않기 하거나 어제 다시보지 않기를 누른 경우
        if (!refuseMarketing || currentDay > refuseMarketing) {
            if (data.maketingInfoYn === 'Y' && (memberStatus === "1" || memberStatus === "2" || memberStatus === "5")) {
                // memberStatus 1 : 14세 이상 vip, 2: 14세 이상 우수, 5: 타사 회원이고 마케팅 팝업 동의를 한 회원에게만 마케팅 팝업 노출
                let response
                try {
                    response = await commonApi.getMarketingNoti({
                        targetAge: await getAgeCode(),
                        targetGender: await getItemByKey("gender") || "A"
                    }, 'v1')
                    //TODO 뭐먼저 보여줄까
                    const data = response.body.popupList[0]
                    const popList = response.body.popupList.slice().reverse()

                    // popupId가 저장되어 있을 경우에 If문을 뚫고 들어온다. 다시 한번 체크
                    queue.push({
                        popupList: popList,
                        type: 'image',
                        title: data.popupTitle,
                        imageUrl: data.imgUrl,
                        submitText: "닫기",
                        showCheckbox: true,
                        onCheckbox: async () => {
                            await addItem("refuseMarketing", currentDay)
                        },
                        onSubmit: () => closePopup(),
                        gtmType: 'ad',
                        setLandingUrl: setLandingUrl
                    })
                    setLandingUrl([popList[0].linkType, popList[0].linkUrl, popList[0].popupTitle]);
                } catch (e) {
                    console.log(e)
                }
            }
        }
        if (data.payExistYn && data.payExistYn === 'Y' && !stopPayExistPopup) {
            const response = await paymentApi.getPayRequest({}, 'v1')
            if (response.header.resultCode === "0000") {
                const data = response.body
                queue.push({
                    type: 'common',
                    title: '휴대폰 결제 요청',
                    description: `${data.payShopName}에서 ${data.payMoney}를 결제할까요?`,
                    submitText: '확인',
                    onSubmit: () => {
                        callCheckPassword(`paymentRequest-${data.otpId}`)
                        closePopup();
                    },
                    cancelText: '닫기',
                    onClose: () => {
                        sendEventReturn('PASSWORD', {page: `paymentRequest-${data.otpId}`})
                        closePopup();
                    }
                });
            } else if (response.header.resultCode === "6505") {
                console.log(response.header.resultCode + response.header.message)
            } else {
                queue.push({
                    type: 'common',
                    title: '휴대폰 결제 요청 실패',
                    description: response.header.resultCode + response.header.message,
                    submitText: '확인',
                    onSubmit: closePopup
                });
            }

        }
        setPopupQueue(queue);

        if (queue.length > 0) {
            setPopupContent(queue[0]);
        }
    };
    const checkTermData = async (serviceYackReqStatus?: string) => {
        const currentDay = await getItemByKey("currentDay")
        const termsHideDuration = await getItemByKey("termsHideDuration")

        const isLessThan7Days = () => {
            const currentDateNumber = parseInt(currentDay);
            const termsHideDateNumber = parseInt(termsHideDuration);

            const difference = currentDateNumber - termsHideDateNumber;
            return difference <= 7;
        };

        const fetchAgreements = async (): Promise<{
            agreements: Agreement[];
            reAgreeContent: string;
            addAgreeContent: string
        }> => {
            try {
                const memberId = await getItemByKey("memberId");
                const memberStatus = await getItemByKey("memberStatus");
                const response = await commonApi.terms.getAgreements({memberId: memberId}, 'v1');
                console.log(response);

                if (response.header.resultCode !== '0000') {
                    showMessagePopup(response.header.message);
                    return {agreements: [], reAgreeContent: '', addAgreeContent: ''}; // 에러 발생 시 빈 배열과 빈 문자열 반환
                }

                let sortedAgreementList: Agreement[] = [];

                // 응답에서 재동의 또는 추가동의 여부에 따라 처리
                if (response.body.reAgreeTermsList.length > 0) {
                    const reAgreeTermsList: Agreement[] = response.body.reAgreeTermsList.map((term: any) => ({
                        termId: term.termsId,
                        termName: term.termsTitle,
                        termsType: term.termsType,
                        optTermCode: term.optTermCode,
                        selected: false,
                        isRequired: term.reqYn === 'Y'
                    }));
                    sortedAgreementList = [
                        ...reAgreeTermsList.filter(term => term.isRequired),
                        ...reAgreeTermsList.filter(term => !term.isRequired)
                    ];
                } else if (response.body.addAgreeTermsList.length > 0) {
                    const addAgreeTermsList: Agreement[] = response.body.addAgreeTermsList.map((term: any) => ({
                        termId: term.termsId,
                        termName: term.termsTitle,
                        termsType: term.termsType,
                        optTermCode: term.optTermCode,
                        selected: false,
                        isRequired: term.reqYn === 'Y'
                    }));
                    sortedAgreementList = [
                        ...addAgreeTermsList.filter(term => term.isRequired),
                        ...addAgreeTermsList.filter(term => !term.isRequired)
                    ];
                }

                // reAgreeContent와 addAgreeContent 포함하여 반환
                return {
                    agreements: sortedAgreementList,
                    reAgreeContent: response.body.reAgreeContent || '',
                    addAgreeContent: response.body.addAgreeContent || ''
                };
            } catch (error) {
                console.error("Error fetching agreements:", error);
                return {agreements: [], reAgreeContent: '', addAgreeContent: ''}; // 에러 발생 시 빈 값 반환
            }
        };

        const checkTermsUpdated = async (agreementList: Agreement[]): Promise<boolean> => {
            // IndexedDB에서 저장된 agreementList를 가져옵니다.
            const savedAgreements: Agreement[] | null = await getItemByKey("agreementList");

            if (!savedAgreements) {
                return true;
            }

            // 두 배열이 동일한지 비교합니다.
            const isSameTerms = agreementList.length === savedAgreements.length &&
                agreementList.every(term =>
                    savedAgreements.some(savedTerm => savedTerm.termId === term.termId)
                );
            // 동일하지 않으면 업데이트가 되었음을 의미하므로 true 리턴
            return !isSameTerms;
        };

        if (serviceYackReqStatus === "164001") {
            if (showTutorial) {
                setNavigateUpdate(true)
            } else {
                navigate('/update')
            }
        }

        if (serviceYackReqStatus === "164002" || serviceYackReqStatus === "164003") {
            try {
                if (!(await getItemByKey('first_loading'))) {
                    const agreementList = await fetchAgreements()
                    // 약관 노출 제한 기간 자체가 선언 안되어 있을시 무조건 보여줌
                    if (!termsHideDuration) {
                        show(<AdditionalAgreements
                            sortedAgreementList={agreementList.agreements}
                            reAgreeContent={agreementList.reAgreeContent}
                            addAgreeContent={agreementList.addAgreeContent}
                        />)
                        // 약관 노출 제한 기간이 7일 이내인 경우 약관 업데이트 여부를 판단
                    } else if (isLessThan7Days()) {
                        if (await checkTermsUpdated(agreementList.agreements)) {
                            show(<AdditionalAgreements
                                sortedAgreementList={agreementList.agreements}
                                reAgreeContent={agreementList.reAgreeContent}
                                addAgreeContent={agreementList.addAgreeContent}
                            />)
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    const closePopup = () => {
        setPopupQueue((prevQueue) => {
            const newQueue = prevQueue.slice(1);
            if (newQueue.length > 0) {
                setPopupContent(newQueue[0]);
            } else {
                setPopupContent(null);
            }
            return newQueue;
        });
    };

    const handleCloseTutorial = async () => {
        await addItem('first_loading', false)
        if (navigateUpdate) {
            navigate('/update')
        }
        setShowTutorial(false);
    };

    // home 에서 안드로이드 백키를 누른 경우
    // 1. 바코드가 떠있으면 바코드를 닫는다.
    // 2. 튜토리얼이 떠있으면 튜토리얼을 닫는다.
    // 3. 그 외에는 종료 팝업을 띄운다
    const handleBackKeyPress = async (haveToAction: boolean) => {
        if (haveToAction) {
            if (showTutorial) {
                await handleCloseTutorial()
            } else {
                await showPopup()
            }
        }
    }

    return (
        <>
            {useLoading(isLoading)}
            {showTutorial ? <Tutorial onClose={handleCloseTutorial}/> :
                <>
                    {openBarcodePopup &&
                        <BarcodePopup onClose={() => setOpenBarcodePopup(false)} onSubmit={() => goAppStore()}
                                      submitText={"업데이트"} cancelText={"취소"}/>}
                    {popupContent && popupContent.type === 'common' && (
                        <CommonPopup
                            onSubmit={popupContent.onSubmit}
                            onClose={popupContent.onClose || closePopup}
                            title={popupContent.title}
                            description={popupContent.description}
                            submitText={popupContent.submitText}
                            cancelText={popupContent.cancelText}
                            showCheckbox={popupContent.showCheckbox}
                            onCheckbox={popupContent.onCheckbox}
                        />
                    )}

                    {popupContent && popupContent.type === 'barcode' && (
                        <BarcodePopup
                            onSubmit={popupContent.onSubmit}
                            onClose={popupContent.onClose || closePopup}
                            title={popupContent.title}
                            description={popupContent.description}
                            submitText={popupContent.submitText}
                            cancelText={popupContent.cancelText}
                        />
                    )}

                    {popupContent && popupContent.type === 'image' && (
                        <CommonPopupWithImage
                            popupList={popupContent.popupList}
                            setLandingUrl={setLandingUrl}
                            onSubmit={handleImageCloseClick}
                            submitText={popupContent.submitText}
                            cancelText={popupContent.cancelText}
                            imageUrl={popupContent.imageUrl || "https://example.com/default-ad-image.jpg"}
                            showCheckbox={popupContent.showCheckbox}
                            onImageClick={handleImageClick}
                            onCheckbox={popupContent.onCheckbox}
                            onClose={popupContent.onClose || closePopup}
                            gtmType={popupContent.gtmType}
                        />
                    )}

                    {!isLoading &&
                        <HomeBenefit userStatus={userStatus} useVipYn={useVipYn}/>
                    }
                    <NavigationBar ref={navigationBarRef} onBackKeyPress={handleBackKeyPress}
                                   barcodeOpen={openBarcode}/>

                    {openErrorPopup &&
                        <CommonPopup description={"일시적인 서비스 오류로 이용이 어려워요.\n다음에 이용부탁드립니다."} submitText={"확인"}
                                     onSubmit={finishApp}/>
                    }
                    {showAdPopup && (
                        <CommonPopupWithImage
                            onClose={() => {
                                setShowAdPopup(false)
                            }}
                            onSubmit={handlePopupSubmit}
                            submitText="앱 종료"
                            cancelText="취소"
                            imageUrl={popupImageUrl || "https://example.com/default-ad-image.jpg"}
                            onImageClick={handleEndImageClick} // 이미지 클릭 핸들러 추가
                            adLogo={ssplogoUrl}
                            logoLanding={logoLandingUrl}
                            gtmType="ad"
                            bottomTitle="U+ 멤버십을 정말 종료하시겠어요?"
                        />
                    )}
                    <CommonLoginPopup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}/>
                </>
            }
            {/*0.미로그인 / 1.14이상 VIP / 2.14이상 우수 / 3.14미만 vip / 4.14미만 우수 / 5.타사*/}
        </>
    );
});

export default HomeBenefitPopup;
