// ****************************************************
// [배포] 5.전체 > 테이터 선물 > 테이터 선물하기 > 선물하기 (UMembershipMyDatagift)
// ****************************************************
// 선물 메시지
import React, { Component } from "react";
import { Box, TextSet, TextArea } from "@lguuxe/2024_designsystem_uni";

const SectionHeadingTextarea = () => {
  return (
    <Box type="1_b" style={{ paddingBottom: "24px" }}>
      <TextSet
        subComponent="Heading_4"
        text={{
          title: (
            <>
              <span>선물 메시지</span>
            </>
          )
          // description: (
          //   <>
          //     <span>데이터 선물과 함께 보내고 싶은 메시지를 작성해주세요. </span>
          //   </>
          // )
        }}
        blockSpacing={{ top: "24px", bottom: "12px" }}
        isIndent={true}
      />
      <Box type="1_trl" style={{ paddingTop: "0" }}>
        <TextArea
          label=""
          maxLength={100}
          placeholder="데이터 선물과 함께 보내고 싶은 메시지를 작성해주세요. "
          rows={5}
          status="default"
          // supportText={[
          //   {
          //     text: "state, icon 미지정"
          //   },
          //   {
          //     icon: "",
          //     status: "default",
          //     text: "state: default, icon 미지정"
          //   },
          //   {
          //     icon: "",
          //     status: "error",
          //     text: "state: error, icon 미지정"
          //   },
          //   {
          //     icon: "",
          //     status: "success",
          //     text: "state: success, icon 미지정"
          //   }
          // ]}
        />
      </Box>
    </Box>
  );
};

export default SectionHeadingTextarea;
