// ****************************************************
// [배포] 5.전체 > 테이터 선물 > 테이터 선물하기 > 선물하기
// ****************************************************
import React, { useEffect, useState } from "react";
import { Tab, Box } from "@lguuxe/2024_designsystem_uni";

import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import SectionHeadingInfobox from "./SectionHeadingInfobox";
import SectionHeadingTextfield from "./SectionHeadingTextfield";
import SectionHeadingTextarea from "./SectionHeadingTextarea";
import CommonDivider from "../../components/Common/CommonDivider";

import "../../css/style.css";
import { handlePromiseResult } from "../../api/api";
import membershipsServiceApi from "../../api/gatewayApi/memberships-service";
import SortingListHistory from "./SortingListHistory";
import DataGiftAccordion from "./DataGiftAccordion";

export interface LimitT {
    cardLevel: string;
    cardLevelName: string;
    dataGiftsSize: string;
    dataGiftsLimit: string;
    noticeList: Array<{
        title: string;
        contents: string;
    }>;
}

const UMembershipMyDatagift = () => {
    const [limit, setLimit] = useState<LimitT>();
    const [selTab, setSelTab] = useState("1");

    useEffect(() => {
        //데이터 선물 가능 횟수 조회 API 호출
        const fetchData = async () => {
            try {
                const [limit] = await Promise.allSettled([membershipsServiceApi.dataGifts.getLimit("v1")]);
                setLimit(handlePromiseResult(limit));
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Box
                type="1_b"
                style={{
                    width: "100%",
                }}
            >
                {/* header */}
                <CommonLeftIconHeader title="데이터 선물하기" />

                {/* 탭 메뉴 */}
                <Tab
                    items={[
                        {
                            id: "1",
                            label: "선물하기",
                        },
                        {
                            id: "2",
                            label: "내역보기",
                        },
                    ]}
                    layout="fullWidth"
                    selectedItemId="1"
                    onItemSelected={(index) => {
                        setSelTab(index);
                    }}
                    style={{
                        padding: "0 20px",
                    }}
                />

                {/* 선물 가능 횟수 */}
                {selTab === "1" && (
                    <>
                        {limit && <SectionHeadingInfobox limit={limit} />}

                        {/* 받는사람 휴대폰 번호 */}
                        <SectionHeadingTextfield />

                        {/* 선물 메시지 */}
                        <SectionHeadingTextarea />
                    </>
                )}
                {selTab === "2" &&
                    <SortingListHistory />
                }
                <CommonDivider />

                {/* 유의사항 */}
                <DataGiftAccordion noticeList={limit?.noticeList}/>
            </Box>
        </>
    );
};

export default UMembershipMyDatagift;
