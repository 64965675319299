import React, {useEffect, useState} from 'react';
import ShortcutSection from '../../components/Entire/ShortcutSection';

import MenuList from '../../components/Entire/MenuList';

import RecommendAppList from '../../components/Entire/RecommendAppList';
import CommonDivider from '../../components/Common/CommonDivider';
import TitleHeader from "../../components/Common/TitleHeader";
import GuideSection from "../../components/Entire/GuideSection";
import guideIcon from '../../images/icon/6_notification/guide_s.svg';
import chatbotIcon from '../../images/icon/6_notification/chatbot_s.svg';
import {useNavigate} from "react-router-dom";
import {getItemByKey} from "../../utils/indexedDB";
import {appTech, chatbot, goMoveData, goMovie} from "../../api/externalApi";
import NavigationBar from "../../components/Common/NavigationBar";
import CommonLoginPopup from "../../components/Common/CommonLoginPopup";
import CommonPopup from "../../components/Common/CommonPopup";
import {brazeEventApi} from "../../api/brazeBridge";

interface EntireProps {
    navigationBarRef: React.Ref<any>;

}

const Entire: React.FC<EntireProps> = ({navigationBarRef}) => {
    const navigate = useNavigate();
    const [memberStatus, setMemberStatus] = useState('0');
    const [userStatus, setUserStatus] = useState('');
    // const [usePay, setUsePay] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    // const {show} = useFullScreen();

    useEffect(() => {
        const fetchData = async () => {
            setMemberStatus(await getItemByKey('memberStatus'));
            setUserStatus(await getItemByKey('userStatus'))
        }
        fetchData();
    }, []);

    const handlePopup = (type: string) => {
        if (type === 'login') {
            setShowLoginPopup(true);
        }

        if (type === 'error') {
            setShowErrorPopup(true);
        }
    }


    const handleClickChatbot = async () => {
        const memberId = await getItemByKey('memberId');
        if (!memberId) {
            setShowLoginPopup(true);
            return;
        }
        chatbot();
    }

    const MEMBERSHIP_MENU = [
        ...(['1', '2', '3', '4', '5'].includes(memberStatus) ? [
            {title: "전체 제휴사", link: "empty", onAction: async () => {
                navigate("/partners");
            }
        },  {title: "VIP콕", link: "/vip-kok"},
            {title: "유플투쁠", link: "/uptp"}] : []),
        ...(['1', '3'].includes(memberStatus) ? [{
            title: "영화예매", link: "empty", onAction: () => {
                goMovie()
            }
        }, {
            title: "데이터 선물하기", link: "empty", onAction: async () => {
                const useVipkokYn = await getItemByKey("useVipkokYn")
                const index = useVipkokYn === 'Y' ? "1" : "0"
                goMoveData(index)
            }
        }] : [])
    ];

    const MISSION_MENU = [
        ...(['1', '2', '5'].includes(memberStatus) ? [{
            title: "출석체크", link: "/mission/attendance"
        }, {title: "리워드 적립/사용내역", link: "empty", onAction: () => appTech('target=MY_PAGE_POINT')},] : []),
        ...(['1', '2'].includes(memberStatus) ? [{
            title: "U+통신요금 할인 현황", link: "empty", onAction: () => appTech('target=LUM_DISCOUNT_COUPON')
        }] : []),
    ];

    const MY_MENU = [
        ...(['1', '2', '3', '4'].includes(memberStatus) ? [{
            title: "내가 받은 혜택", link: "/my/benefit"
        }] : []),
        // ...(['1', '2'].includes(memberStatus) ? [{
        //     title: "내 배지", link: "/my/badge"
        // }] : []),
        ...(['1', '2', '3', '4', '5'].includes(memberStatus) ? [
            {title: "내 쿠폰", link: "/my/coupon"},
            {title: "좋아하는 제휴사", link: "/my/partners"}
        ] : []),
        ...((memberStatus === '1' || memberStatus === '2') && userStatus !== '49' ? [
            {title: "휴대폰결제", link: "/mobile-pay"},
            {title: "POP 서비스", link: "/pop"}
        ] : []),
    ]

    const HELP_MENU = [
        {title: "공지사항", link: "/help/announcement"},
        {title: "자주하는 질문", link: "/help/faq"},
        ...(['1', '2', '3', '4', '5'].includes(memberStatus) ? [{title: "1:1 문의", link: "/help/ask"},] : []),
        {title: "이용약관", link: "/help/terms"},
        {title: "개인정보처리방침", link: "/help/private-policy", isBold: true},
    ]

    return (
        <>
            <div style={{paddingBottom: '66px'}}>
                <TitleHeader title='전체' isNotice={false} memberStatus={memberStatus}/>

                {['0', '1', '2', '5'].includes(memberStatus) &&
                    <ShortcutSection handlePopup={handlePopup} memberStatus={memberStatus}/>
                }

                <GuideSection title={"U+멤버십 혜택 이용 GUIDE"} icon={guideIcon} onClick={() => {
                    navigate('/benefit-guide')
                }}/>
                <CommonDivider/>

                <MenuList title='U+ 멤버십' menuItems={MEMBERSHIP_MENU}/>

                <MenuList title='미션' menuItems={MISSION_MENU}/>

                <MenuList title='마이' menuItems={MY_MENU}/>

                <RecommendAppList handlePopup={handlePopup}/>
                <CommonDivider/>

                <GuideSection title={'궁금한 내용 챗봇에게 물어보기'} icon={chatbotIcon} onClick={handleClickChatbot}/>

                <MenuList title='고객지원' menuItems={HELP_MENU} noDivider={true}/>
            </div>

            <CommonLoginPopup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}/>
            <CommonPopup submitText={'확인'} isOpen={showErrorPopup} onSubmit={() => setShowErrorPopup(false)}
                         description={'일시적인 오류입니다. 잠시후 다시 시도해주세요.'}/>

            <NavigationBar selectedItem={'entire'} ref={navigationBarRef}/>
        </>
    );
};

export default Entire;
