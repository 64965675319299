// ****************************************************
// [배포] 5.전체 > 테이터 선물 > 테이터 선물하기 > 내역보기 (UMembershipMyDatagift_2)
// ****************************************************
// 사용내역
import React, { Component, useEffect, useState } from "react";
import { List, TextSet, Text, Dropdown, Box, Icon, Image } from "@lguuxe/2024_designsystem_uni";
import membershipsServiceApi from "../../api/gatewayApi/memberships-service";
import dayjs from "dayjs";
import { handlePromiseResult } from "../../api/api";
import BS_List from "../../components/Common/BS_List";
import { generateLast12Months } from "../../utils/helper";
import emptyContentImg from "../../images/img/empty/empty_result_120.svg";

interface HistoryT {
    name: string;
    phone: string;
    status: string;
    date: string;
    dataSize: string;
}

const SortingListHistory = () => {
    const [history, setHistory] = useState<Array<HistoryT>>([]);
    const [monthList, setMonthList] = useState<{ id: string; label: string }[]>([]);
    const [isMonthBsOpen, setIsMonthBsOpen] = useState(false);
    const [selDate, setSelDate] = useState<{ year: string; month: string } | undefined>();
    const [selectedDateId, setSelectedDateId] = useState("0");

    const getList = async (date: { year: string; month: string }) => {
        const year = date.year;
        const month = date.month.padStart(2, "0");
        setSelDate({ year: date.year, month: date.month });
        try {
            const [history] = await Promise.allSettled([membershipsServiceApi.dataGifts.getHistory({ date: `${year}${month}` }, "v1")]);
            setHistory(handlePromiseResult(history).dataGiftHisList);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectedMonth = (id: string) => {
        const match = monthList[Number(id)].label.match(/(\d{4})년 (\d{1,2})월/);
        setSelectedDateId(id);
        if (match) {
            getList({ year: match[1], month: match[2] });
        }
    };

    useEffect(() => {
        const now = dayjs();
        getList({ year: String(now.get("y")), month: String(now.get("month") + 1) });
        setMonthList(generateLast12Months(Number(now.get("y")), Number(now.get("M")) + 1));
    }, []);

    return (
        <Box type="1_b">
            {/* 정렬 */}
            <Box
                type="3_trbl"
                style={{
                    paddingBottom: "0",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Text typo="font_heading_5B" color="color_text_neutral_6">
                    선물내역
                </Text>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Text typo="font_heading_5Sb">
                        {selDate?.year}년 {selDate?.month}월
                    </Text>
                    <Icon
                        color="#7f8a94"
                        name="chevron_down_small_line"
                        size="24px"
                        onClick={() => {
                            setIsMonthBsOpen(true);
                        }}
                    />
                </div>
            </Box>

            {/* 쿠폰 목록 */}
            {history.length > 0 ? (
                <List
                    type="view"
                    fill="none"
                    items={history.map((historyItem) => {
                        return {
                            leftSlot: [
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "5px",
                                    }}
                                >
                                    <Text as="span" typo="font_heading_5Sb" color="color_text_neutral_6">
                                        {historyItem.name}({historyItem.phone})
                                    </Text>
                                    <Text as="span" typo="font_body_2M" color="color_text_neutral_4">
                                        {historyItem.date}
                                    </Text>
                                </div>,
                            ],
                            rightSlot: [
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Text as="span" textAlign="right" typo="font_body_1M" color="color_text_neutral_4" style={{ justifyContent: "flex-end" }}>
                                        {historyItem.dataSize}
                                    </Text>
                                    <Text as="span" textAlign="right" typo="font_body_2M" color="color_text_neutral_4" style={{ justifyContent: "flex-end" }}>
                                        {historyItem.status}
                                    </Text>
                                </div>,
                            ],
                        };
                    })}
                />
            ) :
            (
                <div
                    style={{
                        padding: "83px 0 71px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Image src={emptyContentImg} alt="placeholder" width="120px" ratio="1:1" />
                        <div style={{ paddingTop: "20px" }}>
                            <Text typo="font_detail_1M" color="color_text_neutral_3" textAlign="center">
                                선물을 주고 받은 내역이 없어요.
                            </Text>
                        </div>
                    </div>
                </div>
            )}

            {/* 월 선택 바텀 시트 */}
            <BS_List
                dataList={monthList}
                isOpen={isMonthBsOpen}
                onSelected={handleSelectedMonth}
                title="월 선택"
                onClose={() => {
                    setIsMonthBsOpen(false);
                }}
                checkedItem={selectedDateId}
            />
        </Box>
    );
};
export default SortingListHistory;
