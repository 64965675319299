// ****************************************************
// [배포] 5.전체 > 테이터 선물 > 테이터 선물하기 > 선물하기 (UMembershipMyDatagift)
// ****************************************************
// 유의사항
import React, { Component } from "react";
import { Accordion, Text, Button, TextSet } from "@lguuxe/2024_designsystem_uni";

const DataGiftAccordion = ({ noticeList }: { noticeList?: Array<{ title: string; contents: string }> }) => {
    return (
        <div>
            <Accordion
                fill="underline"
                title={
                    <TextSet
                        subComponent="Body_1"
                        text={{
                            title: "꼭 확인하세요",
                        }}
                    />
                }
                size="medium"
                weight="bolder"
                style={{
                    padding: "0",
                }}
            >
                <Accordion.Body>
                    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        {noticeList?.map((notice) => (
                            <>
                                <Text typo="font_body_2M" color="color_text_neutral_3">
                                    {notice.title}
                                </Text>
                                <ul
                                    style={{
                                        paddingLeft: "26px",
                                        margin: "0 0 20px 0",
                                    }}
                                >                   
                                    <li>
                                        <Text typo="font_body_2M" color="color_text_neutral_3">
                                            {notice.contents}
                                        </Text>
                                    </li>
                                </ul>
                            </>
                        ))}
                    </div>
                </Accordion.Body>
            </Accordion>
        </div>
    );
};

export default DataGiftAccordion;
