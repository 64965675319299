// ****************************************************
// [배포] 5.전체 > 테이터 선물 > 테이터 선물하기 > 선물하기 (UMembershipMyDatagift)
// ****************************************************
// 받는사람 휴대폰 번호
import React, { Component, useEffect, useRef, useState } from "react";
import { Box, TextSet, TextField, Button } from "@lguuxe/2024_designsystem_uni";
import membershipsServiceApi from "../../api/gatewayApi/memberships-service";
import { handlePromiseResult } from "../../api/api";

interface AuthT {
    code: string,
    message: string,
}

const SectionHeadingTextfield = () => {
    const ctnTextFieldRef = useRef<any>();
    const [authDisabled, setAuthDisabled] = useState(true);
    const [supportText, setSupportText] = useState<any>([]);
    const [auth, setAuth] = useState<AuthT>();

    const handleTextField = (e: any) => {
        if(e.value.length === 11) {
            setAuthDisabled(false);
        } else {
            setAuthDisabled(true);
        }
    }

    const ctnAuth = async (e: any) => {
        const ctn = ctnTextFieldRef.current?.value;
        try {
            const [auth] = await Promise.allSettled([membershipsServiceApi.dataGifts.auth.putIndex({phone: ctn}, "v2")]);
            setAuth(handlePromiseResult(auth));
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=>{
        if(auth) {
            setSupportText([{
                icon: "",
                status: "error",
                text: auth?.message
            }])
        }
    },[auth])


    return (
        <Box type="1_b" style={{ paddingBottom: "24px" }}>
            <TextSet
                subComponent="Heading_4"
                text={{
                    title: (
                        <>
                            <span>받는 사람 휴대폰 번호</span>
                        </>
                    ),
                    description: (
                        <>
                            <span>U+ 휴대폰을 이용하는 고객에게만 선물할 수 있습니다.</span>
                        </>
                    ),
                }}
                blockSpacing={{ top: "24px", bottom: "12px" }}
                isIndent={true}
            />
            <Box type="1_trl" style={{ paddingTop: "0" }}>
                <TextField
                    ref={ctnTextFieldRef}
                    fill="single"
                    inputType="number"
                    isAutoTab
                    maxLength={11}
                    placeholder="010 1234 5678"
                    onChange={handleTextField}
                    status="default"
                    rightAddons={[
                        <Button size="small" color="neutral" disabled={authDisabled} onClick={ctnAuth}>
                            인증하기
                        </Button>,
                    ]}
                    supportText={supportText}
                ></TextField>
            </Box>
        </Box>
    );
};

export default SectionHeadingTextfield;
